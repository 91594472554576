import React, { useState, useEffect } from "react";
import "./violations.scss";
import CustomTable from "../../Components/CustomTable/customTable";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/joy";
import httpService from "../../Services/httpService";

function Violations({ onSelectViolation }) {
  const [violations, setViolations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCategories, setTotalCategories] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        const response = await httpService.get("/violation/");
        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }
        const data = response.data.results;
        setViolations(
          data.map((violation) => ({
            id: violation.id,
            tableNames: violation.violation_specific_name,
            totalAssignees: violation.total_categories,
            generalName: violation.violation_general_name.name,
            is_active: violation.is_active,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching violations:", error);
        setLoading(false);
      }
    };
    fetchViolations();
  }, []);

  if (loading) {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  }

  const headCells = [
    {
      id: "Violations",
      numeric: false,
      disablePadding: true,
      label: "Violations",
    },
    {
      id: "AssignedViolations",
      numeric: true,
      disablePadding: false,
      label: "",
    },
    {
      id: "categoryNum",
      numeric: true,
      disablePadding: false,
      label: "No. of categories",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleBulkDeleteViolations = (selectedIds) => {
    httpService
      .post("/violation/bulk_delete/", { ids: selectedIds })
      .then((response) => {
        if (response.status === 200) {
        } else {
          console.error("Error deleting selected violations");
        }
      })
      .catch((error) => {
        console.error("Error deleting selected violations:", error);
      });
  };

  const handleEditClick = (id) => {
    const selectedViolation = violations.find(
      (violation) => violation.id === id
    );
    if (onSelectViolation) {
      onSelectViolation(selectedViolation);
    }
    history.push(`/violations/edit/${id}`);
  };

  return (
    <div>
      <CustomTable
        addButtonText="Add new violation"
        addPath="/violation/add"
        editPath="/violation/edit"
        data={violations}
        showGeneralName={true}
        headCells={headCells}
        onBulkDelete={handleBulkDeleteViolations}
        totalCategories={totalCategories}
        onEditClick={handleEditClick}
        showStatus={false}
        showCountry={false}
        showSearchInput={true}
      />
    </div>
  );
}

export default Violations;
