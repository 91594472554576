import React, { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];

function BarChart2({ data }) {
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const state = {
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        colors: colors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: colors,
              fontSize: "12px",
            },
          },
        },
      },
    };
    data?.map((info) => {
      state.series[0]?.data?.push(info.value);
      state.options.xaxis.categories.push(info.key);
    });
    setChartData(state);
  }, [data]);

  return (
    <div id="chart">
      {chartData ? (
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="bar"
          height={270}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>loading</h1>
        </div>
      )}
    </div>
  );
}

export default BarChart2;
