import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { Tooltip, Typography } from "@mui/material";
import { CircularProgress } from "@mui/joy";
import httpService from "../../Services/httpService";

function ResultDetail(props) {
  const { hashURL } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await httpService.get(`/results/detail/${hashURL}/`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching results:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function convertDate(startDate) {
    const date = new Date(startDate);
    return startDate
      ? `${date.getDate()}/${
          date.getUTCMonth() + 1
        }/${date.getFullYear()} (${date.getHours()}:${date.getUTCMinutes()})`
      : "";
  }

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <section>
        {!loading ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="result-detail">
                  <div className="result-detail__address-country-container">
                    <div className="result-detail__address-container">
                      <span className="result-detail__address">Title</span>
                      <span className="result-detail__results">
                        <a href={data.address} target="_blank">
                          <Tooltip title={data.title}>
                            <>{data?.title?.substring(0, 30)}...</>
                          </Tooltip>
                        </a>
                      </span>
                    </div>
                    <div className="result-detail__country-container">
                      <span className="result-detail__country">Country</span>
                      <span>{data.country}</span>
                    </div>
                  </div>
                  <div className="result-detail__section-container">
                    <div className="result-detail__item-container">
                      <span className="result-detail__names">Product</span>
                      <span>{data.product}</span>
                    </div>
                  </div>
                  {data.latest_violations && (
                    <div className="result-detail__section-container">
                      <span className="result-detail__names">Price</span>
                      <div className="result-detail__item-container">
                        {data.latest_violations.map((vio, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              marginTop: "5px",
                            }}
                          >
                            {vio.value.before_discount_price && (
                              <span>
                                Original : {vio.value.before_discount_price}
                              </span>
                            )}
                            {vio.value.price && (
                              <span style={{ marginLeft: "8rem" }}>
                                Discounted : {vio.value.price}{" "}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {data.latest_violations && (
                    <div className="result-detail__section-container">
                      <span className="result-detail__names">
                        Latest violations
                      </span>
                      <div className="result-detail__item-container">
                        {data.latest_violations.map((vio, index) => (
                          <span
                            key={index}
                            style={{
                              textAlign: "center",
                              backgroundColor: "rgba(214, 65, 35, 0.10)",
                              borderRadius: "20px",
                              padding: "3px 8px",
                              color: "#8F4899",
                              width: "70px",
                            }}
                          >
                            {vio.key}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="result-detail__detection-history">
                    <span className="result-detail__detection-history-title">
                      Detection history
                    </span>
                    {data?.detetion_history
                      ? data.detetion_history?.map((item, index) => (
                          <div
                            className="result-detail__detections-container"
                            key={index}
                          >
                            <div className="result-detail__violation-detected-container">
                              <span>{convertDate(item?.crawled_at)}</span>
                              <div className="result-detail__violation-detected">
                                <span className="result-detail__violation-detected-title">
                                  Violation detected
                                </span>
                                <span className="result-detail__violation-detected-length">
                                  {item?.num_violations}
                                </span>
                              </div>
                            </div>
                            {item?.violations_detected.length > 0 && (
                              <div style={{ marginLeft: "10px" }}>
                                {item?.violations_detected?.map(
                                  (vio, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        textAlign: "center",
                                        backgroundColor:
                                          "rgba(214, 65, 35, 0.10)",
                                        borderRadius: "20px",
                                        padding: "3px 8px",
                                        color: "#8F4899",
                                      }}
                                    >
                                      {vio.key}
                                    </span>
                                  )
                                )}
                              </div>
                            )}

                            <div className="result-detail__violation-detected-items">
                              {item?.latest_violations
                                ? item.latest_violations.map(
                                    (violation, index) => (
                                      <span
                                        className="result-detail__violation-detected-item"
                                        key={index}
                                      >
                                        {violation}
                                      </span>
                                    )
                                  )
                                : null}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="result-detail-image">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      className="result-detail-image__image"
                      src={data?.image_url}
                      alt="shot"
                    />
                    <div className="desc__section">
                      {data?.description && (
                        <h2 style={{ textAlign: "left" }}>Description</h2>
                      )}
                      {data?.description?.map((desc, index) => (
                        <Fragment key={index}>
                          {desc?.value && (
                            <h3 style={{ textAlign: "left" }}>{desc?.key}</h3>
                          )}
                          {desc?.value && (
                            <p style={{ textAlign: "left" }}>{desc?.value}</p>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Box>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default ResultDetail;
