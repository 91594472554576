import React, { useState } from "react";
import "./mainPage.scss";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GridIcon from "../../Icons/gridIcon";
import ChartIcon from "../../Icons/chartIcon";
import GlobeIcon from "../../Icons/globeIcon";
import PackIcon from "../../Icons/packIcon";
import FolderIcon from "../../Icons/folderIcon";
import AlertIcon from "../../Icons/alertIcon";
import MailIcon from "../../Icons/mailIcon";
import SettingIcon from "../../Icons/settingIcon";
import logo from "../../Icons/img.png";
import "../../Shared/Colors.scss";
import LogoutIcon from "../../Icons/logoutIcon";
import { StyledEngineProvider } from "@mui/material/styles";
import AvatarDetail from "../../Profile/AvatarDetail/avatarDetail";
import Categories from "../Categories/categories";
import Connectors from "../Connectors/connectors";
import EmailTemplate from "../EmailTemplate/emailTemplate";
import Product from "../Products/product";
import Results from "../Results/results";
import Setting from "../Setting/setting";
import Violations from "../Violations/violations";
import Dashboard from "../Dashboard/dashboard";
import AddNewCategory from "../Categories/addNewCategory";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import EditCategory from "../Categories/editCategory";
import AddNewViolation from "../Violations/addNewViolation";
import EditViolation from "../Violations/editViolation";
import AddNewProduct from "../Products/addNewProduct";
import EditProduct from "../Products/editProduct";
import AddNewConnector from "../Connectors/addNewConnector";
import EditConnector from "../Connectors/editConnector";
import ResultDetail from "../Results/resultDetail";
import ResultSet from "../ResultSet/resultSet";
import EmailHistory from "../EmailHistory/emailHistory";
import SearchIcon from "../../Icons/searchIcon";
import FlagIcon from "../../Icons/flagIcon";
import AddNewEmailTemplate from "../EmailTemplate/addNewEmailTemplate";
import EditEmailTemplate from "../EmailTemplate/editEmailTemplate";

const drawerWidth = 240;
const icons = [
  <GridIcon />,
  <ChartIcon />,
  <SearchIcon />,
  <FlagIcon />,
  <GlobeIcon />,
  <PackIcon />,
  <FolderIcon />,
  <AlertIcon />,
  <MailIcon />,
  // <SettingIcon />,
];

function MainPage({ handleLogout }) {
  const [activeComponent, setActiveComponent] = useState(null);
  const history = useHistory();

  const [activeButtonIndex, setActiveButtonIndex] = useState(() => {
    const storedIndex = localStorage.getItem("activeButtonIndex");
    return storedIndex !== null ? parseInt(storedIndex, 10) : 0;
  });

  const buttons = [
    "Dashboard",
    "Results",
    "Result set",
    "Email history",
    "Connectors",
    "Products",
    "Categories",
    "Violations",
    "Email template",
    // "Setting",
  ];

  const [pageTitle, setPageTitle] = useState(buttons[activeButtonIndex]);

  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);
    setPageTitle(buttons[index]);
    setActiveComponent(null);
    localStorage.setItem("activeButtonIndex", index.toString());
  };
  const componentMapping = {
    0: Dashboard,
    1: Results,
    2: ResultSet,
    3: EmailHistory,
    4: Connectors,
    5: Product,
    6: Categories,
    7: Violations,
    8: EmailTemplate,
    // 9: Setting,
  };

  const isSuperUser = localStorage.getItem("superUser") === "true";
  const restrictedButtons = [
    "Connectors",
    "Products",
    "Categories",
    "Violations",
  ];

  const accessibleButtons = isSuperUser
    ? buttons
    : buttons.filter((button) => !restrictedButtons.includes(button));

  const RenderedComponent =
    activeComponent || componentMapping[activeButtonIndex] || null;

  const handleFilter = (x) => {
    history.push(x);
    handleButtonClick(1);
  };
  return (
    <>
      <Box
        sx={{
          display: "block",
          marginLeft: "15em",
          backgroundColor: "#F6F5F4",
        }}
      >
        <AppBar
          sx={{
            width: "100%",
            ml: `${drawerWidth}px`,
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            position: "relative",
            alignItems: "center",
            paddingTop: "1em",
            justifyContent: "space-between",
            flexDirection: "row",
            marginLeft: "0",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ color: "var(--blue)", fontSize: "2em", fontWeight: "bold" }}
              variant="h6"
              noWrap
              component="div"
            >
              {pageTitle}
            </Typography>
          </Toolbar>
          <StyledEngineProvider injectFirst>
            <AvatarDetail />
          </StyledEngineProvider>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              alignItems: "center",
              boxSizing: "border-box",
              backgroundColor: "var(--lightest-gray)",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="logo-container">
            <img className="logo-container__logo-image" src={logo} alt="" />
          </div>

          <List sx={{ width: "100%" }}>
            {accessibleButtons.map((text, index) => {
              const path = `/${text.toLowerCase().replace(/ /g, "-")}`;

              return (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{ width: "calc(100% - 16px)", margin: "0 8px" }}
                >
                  <ListItemButton
                    component={Link}
                    to={path}
                    onClick={() => handleButtonClick(index)}
                    sx={{
                      borderRadius: "0.5em",
                      backgroundColor:
                        activeButtonIndex === index ? "var(--blue)" : "initial",
                      color: activeButtonIndex === index ? "white" : "initial",
                      "&:hover": {
                        backgroundColor:
                          activeButtonIndex === index
                            ? "var(--blue)"
                            : "initial",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        stroke:
                          activeButtonIndex === index
                            ? "white"
                            : "var(--light-brown)",
                        border: "none",
                        minWidth: "40px",
                      }}
                    >
                      {icons[index] || null}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color:
                          activeButtonIndex === index
                            ? "white"
                            : "var(--light-brown)",
                      }}
                      primary={text}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
            <ListItemButton
              onClick={handleLogout}
              sx={{ width: "calc(100% - 16px)", margin: "2em 8px 0 8px" }}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: "var(--pink)" }} primary="Logout" />
            </ListItemButton>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Switch>
            {isSuperUser && (
              <Route path="/categories/add" component={AddNewCategory} />
            )}
            {isSuperUser && (
              <Route path="/violations/edit/:id" component={EditViolation} />
            )}
            {isSuperUser && (
              <Route path="/categories/edit/:id" component={EditCategory} />
            )}
            {isSuperUser && (
              <Route path="/violation/add" component={AddNewViolation} />
            )}
            {isSuperUser && (
              <Route path="/products/add" component={AddNewProduct} />
            )}
            {isSuperUser && (
              <Route path="/connectors/edit/:id" component={EditConnector} />
            )}
            {isSuperUser && (
              <Route path="/connectors/add" component={AddNewConnector} />
            )}
            {isSuperUser && (
              <Route path="/products/edit/:id" component={EditProduct} />
            )}
            <Route path="/results/detail/:hashURL" component={ResultDetail} />
            <Route path="/email-template/add" component={AddNewEmailTemplate} />
            <Route
              path="/email-template/edit/:id"
              component={EditEmailTemplate}
            />
            <Route path="/result-set">
              <ResultSet filter={handleFilter} />
            </Route>
            <Route path="/email-history" component={EmailHistory} />
            <Route path="/">
              <RenderedComponent />
            </Route>
          </Switch>
        </Box>
      </Box>
    </>
  );
}

export default MainPage;
