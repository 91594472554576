import React, { useEffect, useState } from "react";
import "./connectors.scss";
import CustomTable from "../../Components/CustomTable/customTable";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress } from "@mui/joy";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

function Connectors() {
  const [connectors, setConnectors] = useState([]);
  const [tooltipContent, setTooltipContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const history = useHistory();

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchConnectors = async () => {
      try {
        const response = await httpService.get("/connector/");

        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }

        console.log(response);
        const data = response.data.results;

        setConnectors(
          data.map((connector) => ({
            id: connector.id,
            tableNames: connector.name,
            connectorCountry: connector.country.code,
            status: connector.status,
            lastRun: connector.last_run,
            assignedViolation: connector.products.map((product) => (
              <div className="custom-table__general-name-item" key={product.id}>
                {product.name}
              </div>
            )),
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching connectors:", error);
        setLoading(false);
      }
    };

    fetchConnectors();
  }, [history]);

  const headCells = [
    {
      id: "connector",
      numeric: false,
      disablePadding: true,
      label: "Connector",
    },
    {
      id: "AssignedProducts",
      numeric: true,
      disablePadding: false,
      label: "Assigned products",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "LastRun",
      numeric: true,
      disablePadding: false,
      label: "Last run",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: "",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  if (loading) {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  }

  const handleBulkDeleteConnectors = (selectedIds) => {
    httpService
      .post("/connector/bulk_delete/", { ids: selectedIds })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bulk delete successful");
          const newConnectors = connectors.filter(
            (conn) => !selectedIds.includes(conn.id)
          );
          console.log(newConnectors);
          setConnectors([...newConnectors]);
        } else {
          toast.error("Error deleting selected connectors");
        }
      })
      .catch((error) => {
        toast.error(`Error deleting selected connectors: ${error}`);
      });
  };

  const handleBulkRunConnectors = (selectedIds) => {
    httpService
      .post("/connector/bulk_run/", { ids: selectedIds })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Connctors Run Successfully.");
        } else {
          toast.error("Error running selected connectors");
          toast.error("Run Connectors Failed!");
        }
      })
      .catch((error) => {
        console.error("Error running selected connectors:", error);
        toast.error(`Run Connectors Failed! ${error}`);
      });
  };

  return (
    <>
      <Toaster />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <CustomTable
        headCells={headCells}
        addButtonText="Add new connector"
        addPath="/connectors/add"
        editPath="/connectors/edit"
        data={connectors}
        tooltipContent={tooltipContent}
        onBulkDelete={handleBulkDeleteConnectors}
        onBulkRun={handleBulkRunConnectors}
        showGeneralName={false}
        showStatus={true}
        showCountry={true}
        showSearchInput={true}
        showLastRun={true}
        showCheckBox={true}
      />
    </>
  );
}

export default Connectors;
