import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainPage from "../../Pages/MainPage/mainPage";

const Layout = ({ onLogout }) => {
  const history = useHistory();

  const handleLogout = () => {
    onLogout();
    history.push("/login");
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("accessToken");
    if (!isLoggedIn) {
      history.push("/login");
    }
  }, []);

  return (
    <>
      <MainPage handleLogout={handleLogout} />
    </>
  );
};

export default Layout;
