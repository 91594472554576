import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../Shared/Colors.scss";
import { Chip, InputLabel, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import httpService from "../../Services/httpService";
import { CircularProgress } from "@mui/joy";
import toast, { Toaster } from "react-hot-toast";

function AddNewCategory() {
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const [names, setNames] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSave = () => {
    const formData = {
      name: categoryName,
      violations: selectedViolations,
      is_active: isActive,
      description: description,
    };
    setLoading(true);
    httpService
      .post("/category/", formData)
      .then((response) => {
        if (response.status === 201) {
          // Show the Snackbar with a success message
          toast.success("Category created successfully!");
        } else {
          toast.error("Create category failed");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error.message}`);
        setLoading(false);
      });
  };

  const handleSelectChange = (event) => {
    console.log(event.target);
    const { value } = event.target;
    setSelectedViolations(value);
  };

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        setLoading(true);
        const response = await httpService.get("/violation/");
        const data = response.data.results;
        setNames(
          data.map((item) => ({
            id: item.id,
            name: item.violation_general_name.name,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching names:", error);
        setLoading(false);
      }
    };

    fetchViolations();
  }, []);

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="container">
            <div>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  severity="success"
                  onClose={handleSnackbarClose}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>

              <Button
                // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
                sx={{
                  textTransform: "none",
                  cursor: "pointer",
                  color: "var(--blue)",
                  fontWeight: "bold",
                  fontSize: "1.5em",
                  display: "flex",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => history.goBack()}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "3px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                  >
                    <path
                      d="M25.3337 16.5H6.66699"
                      stroke="#64513D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                      stroke="#64513D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Add new Category
              </Button>
            </div>
            <div className="container__inputs">
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginBottom: "1em" }}
              >
                Main info
              </InputLabel>
              <TextField
                label="New Category"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                id="outlined-basic"
                placeholder="Category name"
                variant="outlined"
                sx={{ width: "20vw", backgroundColor: "white" }}
              />
              <Textarea
                sx={{ marginTop: "2em" }}
                minRows={4}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder="Description (Optional)"
              />
              <FormControlLabel
                sx={{ margin: "2em 0" }}
                labelPlacement="start"
                control={
                  <Switch
                    checked={isActive}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Activation"
              />
              <span className="container__assignee-title">
                Assign violations
              </span>
              <Select
                sx={{ width: "100%", backgroundColor: "white" }}
                multiple
                value={selectedViolations}
                onChange={handleSelectChange}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((violation, index) => (
                      <Chip
                        key={index}
                        label={
                          names.find((name) => name.id === violation)?.name ||
                          ""
                        }
                        onDelete={() =>
                          setSelectedViolations((prevSelected) =>
                            prevSelected.filter(
                              (option) => option !== violation
                            )
                          )
                        }
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        color="primary"
                        variant="filled"
                      />
                    ))}
                  </Stack>
                )}
              >
                {names.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="container__buttons-container">
              <div className="container__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default AddNewCategory;
