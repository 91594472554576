import React, { useState, useEffect } from "react";
import "../../Shared/Colors.scss";
import { Chip, InputLabel, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress } from "@mui/joy";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/base";
import DeleteIcon from "../../Icons/deleteIcon";
import CopyIcon from "../../Icons/copyIcon";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Textarea from "@mui/joy/Textarea";
import { Container } from "@mui/joy";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "../../Icons/checkIcon";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

function EditEmailTemplate() {
  const { id } = useParams();
  const history = useHistory();
  const [violationName, setViolationName] = useState("");
  const [connectors, setConnectors] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [text, setText] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedConnectors, setSelectedConnectors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);

  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/dashboard/v1`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [connectorResponse, emailTemplateResponse] = await Promise.all([
          httpService.get("/connector/"),
          httpService.get(`/email-template/${id}/`),
        ]);
        setConnectors(connectorResponse?.data?.results);
        setSelectedConnectors(
          emailTemplateResponse?.data.connector.map((item) => item.concated)
        );
        setText(emailTemplateResponse?.data.text);
        setViolationName(emailTemplateResponse?.data.name);
        setSubject(emailTemplateResponse?.data.subject);
        setIsActive(emailTemplateResponse?.data.is_active);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedConnectors(value);
  };

  const handleSwitchChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleDelete = () => {
    const list = [];
    list.push(parseInt(id, 10));

    setLoading(true);
    httpService
      .post("/email-template/bulk_delete/", { ids: list })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bulk delete successful");
          history.goBack();
        } else {
          console.error("Error deleting selected category");
          toast.error("Error deleting selected category");
        }
        setLoading(false);
        history.goBack();
      })
      .catch((error) => {
        console.error("Error deleting selected category:", error);
        toast.error(`Error deleting selected category ${error}`);

        setLoading(false);
      });
  };

  const handleSave = (saveMode = true) => {
    const connectorIds = [];
    for (let connector of connectors) {
      selectedConnectors.map((item) => {
        if (item === connector.concated) {
          connectorIds.push(connector.id);
        }
      });
    }
    const formData = {
      name: violationName,
      text,
      sending_authomatic: false,
      is_active: isActive,
      connector: connectorIds,
      subject,
    };

    const url = saveMode
      ? `${apiUrl}/email-template/${id}/`
      : `${apiUrl}/email-template/`;
    setLoading(true);
    httpService[saveMode ? "put" : "post"](url, formData)
      .then((response) => {
        if (response.status === (saveMode ? 200 : 201)) {
          toast.success("email created successfully!");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error sending data to API:", error);
        toast.error(`Error sending data to API: ${error}`);

        setLoading(false);
      });
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        <CircularProgress sx={{ margin: "auto" }} />
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="edit-template">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <Button
              // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                color: "var(--blue)",
                fontWeight: "bold",
                fontSize: "1.5em",
                display: "flex",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => history.goBack()}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                >
                  <path
                    d="M25.3337 16.5H6.66699"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Edit {violationName ? violationName : null}
            </Button>
            <Container
              component="div"
              sx={{ padding: "0 !important", display: "flex" }}
            >
              <div className="add-new-template__form-container">
                <InputLabel
                  htmlFor="component-simple"
                  sx={{ marginBottom: "1em" }}
                >
                  Main info
                </InputLabel>
                <div>
                  <InputLabel
                    htmlFor="component-simple"
                    sx={{
                      marginTop: "1em",
                      fontSize: ".8em",
                      marginLeft: ".5em",
                    }}
                  >
                    Template name
                  </InputLabel>
                  <TextField
                    value={violationName}
                    onChange={(e) => setViolationName(e.target.value)}
                    placeholder="Template name"
                    sx={{ width: "100%", backgroundColor: "white" }}
                  />
                </div>
                {/* subject */}
                <div>
                  <InputLabel
                    htmlFor="component-simple"
                    sx={{
                      marginTop: "1em",
                      fontSize: ".8em",
                      marginLeft: ".5em",
                    }}
                  >
                    Subject
                  </InputLabel>
                  <TextField
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Template name"
                    sx={{ width: "100%", backgroundColor: "white" }}
                  />
                </div>
                {/* subject */}

                <div>
                  <InputLabel
                    htmlFor="component-simple"
                    sx={{
                      marginTop: "1em",
                      fontSize: ".8em",
                      marginLeft: ".5em",
                    }}
                  >
                    Email text
                  </InputLabel>
                  <Textarea
                    minRows={10}
                    id="textarea"
                    onChange={handleTextareaChange}
                    value={text}
                    placeholder="Email text"
                    sx={{ marginTop: "1em" }}
                  />
                </div>
                <FormControlLabel
                  sx={{ margin: "2em 0" }}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={handleSwitchChange}
                      checked={isActive}
                      color="primary"
                    />
                  }
                  label="Activation"
                />
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{
                    fontSize: "1.2em",
                    color: "black",
                    marginBottom: "1em",
                  }}
                >
                  Assign connectors
                </InputLabel>
                <FormControl sx={{ width: 300 }}>
                  <Select
                    sx={{ width: "100%" }}
                    multiple
                    value={selectedConnectors}
                    onChange={handleSelectChange}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((violation, index) => (
                          <Chip
                            key={index}
                            label={violation}
                            onDelete={() =>
                              setSelectedConnectors((prevSelected) =>
                                prevSelected.filter(
                                  (option) => option !== violation
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            color="primary"
                            variant="filled"
                          />
                        ))}
                      </Stack>
                    )}
                  >
                    {connectors.map((connector) => (
                      <MenuItem
                        key={connector.id}
                        value={connector.concated}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {connector.concated}
                        {selectedConnectors.includes(connector.concated) ? (
                          <CheckIcon color="info" />
                        ) : null}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="add-new-template__preview-container">
                <div className="add-new-template__preview-title">
                  <span>Preview</span>
                  {text ? (
                    <div style={{ maxWidth: "500px" }}>
                      <pre className="add-new-template__preview-box">
                        {text}
                      </pre>
                    </div>
                  ) : (
                    <p className="add-new-template__preview-box">
                      Type something to see the preview...
                    </p>
                  )}
                </div>
              </div>
            </Container>
            <div className="edit-template__form-container">
              <div>
                <p className="edit-template__actions-title">Actions</p>
                <Button
                  startIcon={<CopyIcon />}
                  onClick={() => handleSave(false)}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: "0.4em 2em",
                    marginRight: "1em",
                    stroke: "white",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Duplicate
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--pink)",
                    color: "white",
                    padding: "0.4em 2em",
                    "&:hover": {
                      backgroundColor: "var(--pink)",
                      color: "var(--white)",
                    },
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="edit-template__buttons-container">
              <div className="edit-template__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSave(true)}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default EditEmailTemplate;
