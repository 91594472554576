import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../Shared/Colors.scss";
import { InputLabel, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/base";
import Textarea from "@mui/joy/Textarea";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import httpService from "../../Services/httpService";
import { CircularProgress } from "@mui/joy";
import toast, { Toaster } from "react-hot-toast";

function AddNewViolation() {
  const history = useHistory();
  const [violationName, setViolationName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedGeneralName, setSelectedGeneralName] = useState();
  const [isActive, setIsActive] = useState(false);
  const [names, setNames] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedGeneralName(value);
  };

  const handleSwitchChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchGeneralNames = async () => {
      try {
        setLoading(true);
        const response = await httpService.get("/general-name-violation/");
        const data = response.data.results;
        setNames(data.map((item) => ({ value: item.id, label: item.name })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching names:", error);
        setLoading(false);
      }
    };
    fetchGeneralNames();
  }, []);

  const handleSave = () => {
    const formData = {
      violation_specific_name: violationName,
      violation_general_name: selectedGeneralName,
      is_active: isActive,
      description: description,
    };
    setLoading(true);
    httpService
      .post("/violation/", formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Violation created successfully!");
        } else {
          toast.error("Create violation failed!");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error sending data to API:", error);
        toast.success(`Error sending data to API: ${error}`);
        setLoading(false);
      });
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="add-new-violation">
            <div>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  severity="success"
                  onClose={handleSnackbarClose}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
              <Button
                // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
                sx={{
                  textTransform: "none",
                  cursor: "pointer",
                  color: "var(--blue)",
                  fontWeight: "bold",
                  fontSize: "1.5em",
                  display: "flex",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => history.goBack()}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "3px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                  >
                    <path
                      d="M25.3337 16.5H6.66699"
                      stroke="#64513D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                      stroke="#64513D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Add new Violation
              </Button>
            </div>
            <div className="add-new-violation__form-container">
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginBottom: "1em" }}
              >
                Main info
              </InputLabel>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{ fontSize: ".7em", marginLeft: ".5em" }}
              >
                General name
              </InputLabel>
              <FormControl sx={{ width: 300 }}>
                <Select
                  onChange={handleSelectChange}
                  sx={{ width: "100%", backgroundColor: "white" }}
                  value={selectedGeneralName}
                >
                  {names.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      {name.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="add-new-violation__titles">
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{ fontSize: ".7em", marginLeft: ".5em" }}
                >
                  Specific name (Optional)
                </InputLabel>
                <TextField
                  value={violationName}
                  onChange={(e) => setViolationName(e.target.value)}
                  id="outlined-basic"
                  placeholder="Violation name"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "white" }}
                />
              </div>
              <div className="add-new-violation__titles">
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{ fontSize: ".7em", marginLeft: ".5em" }}
                >
                  Description
                </InputLabel>
                <Textarea
                  minRows={4}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder="Description (Optional)"
                />
              </div>
              <FormControlLabel
                sx={{ margin: "2em 0" }}
                labelPlacement="start"
                control={
                  <Switch
                    onChange={handleSwitchChange}
                    checked={isActive}
                    color="primary"
                  />
                }
                label="Activation"
              />
            </div>
            <div className="add-new-violation__buttons-container">
              <div className="add-new-violation__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default AddNewViolation;
