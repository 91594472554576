import React, { useState, useEffect } from "react";
import "./customTable.scss";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {
  IconButton,
  ListItemText,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../Icons/searchIcon";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import EditIcon from "../../Icons/editIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import CheckIcon from "../../Icons/checkIcon";
import XIcon from "../../Icons/xIcon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextFields } from "@mui/icons-material";
import { CircularProgress } from "@mui/joy";
import httpService from "../../Services/httpService";

function CustomTable2({
  addButtonText,
  editPath = "/",
  addPath = "/",
  data,
  totalRows,
  currentPage,
  rowsPerPages,
  headCells,
  onBulkDelete,
  onBulkRun,
  tooltipContent,
  showGeneralName,
  onEditClick,
  showStatus,
  showLastRun = false,
  showCountry,
  tableLoading,
  showSearchInput,
  showFilter,
  actions,
  showCheckBox,
  showSearch,
  showActions = true,
  setShowModal,
  setHashUrl,
  sellerNames,
  sellerNameSelected,
  countries,
  countrySelected,
  clusters,
  clusterSelected,
  products,
  productSelected,
  violations,
  violationSelected,
  setDefaultefaultSelectedViolations,
}) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [itemsToDelete, setItemsToDelete] = React.useState([]);
  const [sellerName, setSellerName] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [cluster, setCluster] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [violation, setViolation] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [violationss, setViolationss] = useState([]);

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const handleEditButtonClick = (id, tableNames) => {
    if (onEditClick) {
      onEditClick(id, tableNames);
    }
  };

  const renderEditButton = (id) => (
    <IconButton
      onClick={() => handleEditButtonClick(id)}
      sx={{
        backgroundColor: "var(--blue)",
        borderRadius: "20%",
        a: { height: "1em" },
        "&:hover": {
          backgroundColor: "var(--blue)",
        },
      }}
      aria-label="edit"
      size="small"
    >
      <Link to={`${editPath}/${id}`}>
        <EditIcon fontSize="inherit" />
      </Link>
    </IconButton>
  );

  const handleBulk = (onDelete = true) => {
    const updatedRows = rows.filter(
      (row) => !selected.includes(row.tableNames)
    );
    setItemsToDelete([...selected]);
    setSelected([]);
    setRows(updatedRows);
    const selectedIdsForDeletion = selected?.map((selectedItem) => {
      const matchingRow = rows.find((row) => row.hashURl === selectedItem);
      return matchingRow.hashURl;
    });
    if (onBulkDelete && onDelete) {
      onBulkDelete(selectedIdsForDeletion);
    } else if (onBulkRun && !onDelete) {
      onBulkRun(selectedIdsForDeletion);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.hashURl);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleEmailClick = (hashUrl) => {
    setHashUrl(hashUrl);
    setShowModal(true);
  };

  const handleClick = (event, tableId) => {
    const selectedIndex = selected.indexOf(tableId);
    let newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(tableId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
    setItemsToDelete(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    currentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPages(parseInt(event.target.value, 10));
    currentPage(0);
    setPage(0);
  };

  const isSelected = (tableId) => selected.indexOf(tableId) !== -1;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    const searchFields = [
      row.tableNames ? row.tableNames.toString() : "",
      row.assignedViolation ? row.assignedViolation.toString() : "",
      row.product ? row.product.toString() : "",
      row.title ? row.title.toString() : "",
      row.name ? `${row.name.toString()} / ${row.country.toString()}` : "",
    ];
    return searchFields.some((field) =>
      field.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  function convertDate(dateToConvert) {
    const date = new Date(dateToConvert);
    return dateToConvert
      ? `${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()}`
      : "";
  }

  function convertTime(timeToConvert) {
    const date = new Date(timeToConvert);
    return timeToConvert ? `${date.getHours()}:${date.getUTCMinutes()}` : "";
  }

  function resetPagination() {
    currentPage(0);
    setPage(0);
    rowsPerPages(10);
    setRowsPerPage(10);
  }

  const handleConnectorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSellerName(typeof value === "string" ? value.split(",") : value);
    sellerNameSelected(typeof value === "string" ? value.split(",") : value);
    resetPagination();
  };

  let countries2 = [...country];
  const handleCountryChange = (couny) => {
    const doesExist = country?.some((obj) => obj.name === couny.name);
    if (doesExist) {
      countries2 = country.filter((item) => item.name !== couny.name);
    } else {
      const data = {
        name: couny.name,
        id: couny.id,
      };
      countries2.push(data);
    }

    setCountry(countries2);
    countrySelected(countries2?.map((m) => m.id));
    resetPagination();
  };

  let clusters2 = [...cluster];
  const handleClusterChange = (clus) => {
    const doesExist = cluster?.some((obj) => obj.name === clus.name);
    if (doesExist) {
      clusters2 = cluster.filter((item) => item.name !== clus.name);
    } else {
      const data = {
        name: clus.name,
        id: clus.id,
      };
      clusters2.push(data);
    }
    setCluster(clusters2);
    clusterSelected(clusters2?.map((m) => m.id));
    resetPagination();
  };

  let products2 = [...product];
  const handleProductChange = (prod) => {
    const doesExist = product?.some((obj) => obj.name === prod.name);
    if (doesExist) {
      products2 = product.filter((item) => item.name !== prod.name);
    } else {
      const data = {
        name: prod.name,
        id: prod.id,
      };
      products2.push(data);
    }
    setProduct(products2);
    productSelected(products2?.map((m) => m.id));
    resetPagination();
  };

  let violations2 = [...violation];
  const handleViolationChange = (vio) => {
    const doesExist = violation?.some((obj) => obj.name === vio.name);
    if (doesExist) {
      violations2 = violation.filter((item) => item.name !== vio.name);
    } else {
      const data = {
        name: vio.name,
        id: vio.id,
      };
      violations2.push(data);
    }

    setViolation(violations2);
    violationSelected(violations2?.map((m) => m.name));
    setDefaultefaultSelectedViolations(violations2?.map((m) => m.name));
    resetPagination();
  };

  useEffect(() => {
    const getViolations = () => {
      httpService
        .get("/violation/")
        .then((response) => {
          const uniqueViolationNamesWithIdSet = new Set(
            response.data.results.map(
              (item) =>
                `${item.violation_general_name.id}:${item.violation_general_name.name}`
            )
          );

          const uniqueViolationNamesWithIdArray = [
            ...uniqueViolationNamesWithIdSet,
          ].map((entry) => {
            const [id, name] = entry.split(":");
            return { id, name };
          });
          const vaiola = [];
          uniqueViolationNamesWithIdArray?.map((vi) => vaiola.push(vi.name));
          setViolation(uniqueViolationNamesWithIdArray);
        })
        .catch((error) => {
          console.error("Error fetching violations:", error);
        });
    };
    getViolations();
  }, []);

  return (
    <Box className="custom-table" sx={{ width: "100%" }}>
      {showSearch && (
        <Box
          sx={{
            padding: "1em",
            borderRadius: ".7em",
            border: "solid lightgray .1em",
            backgroundColor: "white",
            marginBottom: "1em",
          }}
        >
          <Toolbar
            disableGutters={true}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker label="Basic date picker" />
              </DemoContainer>
            </LocalizationProvider>
          </Toolbar>
        </Box>
      )}
      {showFilter ? (
        <Box
          sx={{
            padding: "1em",
            borderRadius: ".7em",
            border: "solid lightgray .1em",
            backgroundColor: "white",
            margin: "2em 0 1em 0",
          }}
        >
          <Toolbar
            disableGutters={true}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{ backgroundColor: "white" }}
                  label="Select result set"
                />
              </DemoContainer>
            </LocalizationProvider> */}
          </Toolbar>
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="sellerNameLabel">Seller Name</InputLabel>
                <Select
                  labelId="sellerNameLabel"
                  multiple
                  value={sellerName}
                  onChange={handleConnectorChange}
                  input={<OutlinedInput label="Seller Name" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {sellerNames?.map((seller, index) => (
                    <MenuItem key={index} value={seller}>
                      <Checkbox checked={sellerName.indexOf(seller) > -1} />
                      <ListItemText primary={seller} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="countryLabel">Country</InputLabel>
                <Select
                  labelId="countryLabel"
                  multiple
                  value={country}
                  input={<OutlinedInput label="Country" />}
                  renderValue={(selected) =>
                    selected?.map((m) => m.name).join(", ")
                  }
                >
                  {countries?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={
                          country?.map((m) => m.id).indexOf(item.id) > -1
                        }
                        onChange={() => handleCountryChange(item)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="clusterLabel">Cluster</InputLabel>
                <Select
                  labelId="clusterLabel"
                  multiple
                  value={cluster}
                  input={<OutlinedInput label="Cluster" />}
                  renderValue={(selected) =>
                    selected?.map((m) => m.name).join(", ")
                  }
                >
                  {clusters?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={
                          cluster?.map((m) => m.id).indexOf(item.id) > -1
                        }
                        onChange={() => handleClusterChange(item)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="productLabel">Product</InputLabel>
                <Select
                  labelId="productLabel"
                  multiple
                  value={product}
                  input={<OutlinedInput label="Product" />}
                  renderValue={(selected) =>
                    selected?.map((m) => m.name).join(", ")
                  }
                >
                  {products?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={
                          product?.map((m) => m.id).indexOf(item.id) > -1
                        }
                        onChange={() => handleProductChange(item)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="violationLabel">Violation</InputLabel>
                <Select
                  labelId="violationLabel"
                  multiple
                  value={violation}
                  input={<OutlinedInput label="Violation" />}
                  renderValue={(selected) =>
                    selected?.map((m) => m.name).join(", ")
                  }
                >
                  {violations?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={
                          violation?.map((m) => m.id).indexOf(item.id) > -1
                        }
                        onChange={() => handleViolationChange(item)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Toolbar>
        </Box>
      ) : (
        showSearchInput && (
          <Toolbar
            sx={{
              display: "flex",
              width: "100%",
              padding: "0 !important",
              marginBottom: "2em",
              justifyContent: "space-between",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {addButtonText && (
              <Button
                component={Link}
                to={addPath}
                sx={{
                  width: "fit-content",
                  backgroundColor: "var(--blue)",
                  textTransform: "none",
                  fontSize: "1.2em",
                  color: "white",
                  "&:hover": { backgroundColor: "var(--blue)", color: "white" },
                }}
                startIcon={<AddIcon />}
              >
                {addButtonText}
              </Button>
            )}
          </Toolbar>
        )
      )}
      <div className="custom-table__bulk-delete-button">
        {selected.length > 0 && (
          <Button
            sx={{
              backgroundColor: "var(--pink)",
              color: "white",
              width: "15vw",
              display: "flex",
              marginBottom: "2em",
              "&:hover": {
                backgroundColor: "var(--light-red)",
                color: "white",
              },
            }}
            onClick={() => handleBulk(true)}
          >
            Delete selected
          </Button>
        )}

        {onBulkRun && selected.length > 0 && (
          <Button
            sx={{
              backgroundColor: "var(--dark-green)",
              color: "white",
              width: "15vw",
              display: "flex",
              marginBottom: "2em",
              "&:hover": { backgroundColor: "var(--green)", color: "white" },
            }}
            onClick={() => handleBulk(false)}
          >
            Run selected
          </Button>
        )}
      </div>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
              <TableRow>
                <TableCell padding="checkbox">
                  {showCheckBox && (
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < filteredRows.length
                      }
                      checked={
                        filteredRows.length > 0 &&
                        selected.length === filteredRows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  )}
                </TableCell>
                {headCells?.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "center" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!tableLoading ? (
                filteredRows.length > 0 ? (
                  filteredRows?.map((row, index) => {
                    const isItemSelected = isSelected(row.hashURl);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          {showCheckBox && (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.hashURl)
                              }
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {showGeneralName && (
                            <span className="custom-table__general-name">
                              {row.generalName}
                            </span>
                          )}
                          {row.tableNames}
                          {showCountry && (
                            <span className="custom-table__connector-country">
                              / {row.connectorCountry}
                            </span>
                          )}
                        </TableCell>
                        {row.title && (
                          <TableCell align="center">
                            <Tooltip title={row.title}>
                              <>{row.title?.substr(0, 40)}...</>
                            </Tooltip>
                          </TableCell>
                        )}
                        <TableCell
                          sx={row.assignedViolationStyle}
                          align="center"
                        >
                          {row.assignedViolation}
                        </TableCell>
                        {showStatus && (
                          <TableCell align="right">
                            {row.status ? <CheckIcon /> : <XIcon />}
                          </TableCell>
                        )}
                        {showLastRun && (
                          <TableCell align="right">
                            {convertDate(row.lastRun)}
                            <br />
                            {convertTime(row.lastRun)}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {row.totalAssignees && (
                            <span
                              style={{
                                backgroundColor: "#D641231A",
                                color: "#D64123",
                                padding: "5px 10px",
                                borderRadius: "15px",
                              }}
                            >
                              {row.totalAssignees}
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {row.email_sent && (
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexGrow={1}
                              pr={2}
                            >
                              <CheckIcon />
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            sx={{
                              backgroundColor: `${
                                row.totalAssignees.length === 0
                                  ? "gray"
                                  : "var(--blue)"
                              }`,
                              cursor: `${
                                row.totalAssignees.length === 0
                                  ? "not-allowed"
                                  : "pointer"
                              }`,
                              color: "white",
                              "&:hover": {
                                backgroundColor: "var(--blue)",
                                color: "white",
                              },
                            }}
                            onClick={() => handleEmailClick(row.hashURl)}
                            disabled={
                              row.totalAssignees.length === 0 ? true : false
                            }
                          >
                            <span
                              style={{
                                marginRight: "3px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M2.66683 2.66667H13.3335C14.0668 2.66667 14.6668 3.26667 14.6668 4.00001V12C14.6668 12.7333 14.0668 13.3333 13.3335 13.3333H2.66683C1.9335 13.3333 1.3335 12.7333 1.3335 12V4.00001C1.3335 3.26667 1.9335 2.66667 2.66683 2.66667Z"
                                  stroke="#D9F2FF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.6668 4L8.00016 8.66667L1.3335 4"
                                  stroke="#D9F2FF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            Email
                          </Button>
                          <Link to={`/results/detail/${row.hashURl}`}>
                            <Button
                              sx={{
                                backgroundColor: "#F6F5F4",
                                color: "black",
                                marginLeft: "1em",
                                "&:hover": {
                                  backgroundColor: "#F6F5F4",
                                  color: "black",
                                },
                              }}
                            >
                              Detail
                              <span
                                style={{
                                  marginLeft: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M6 12L10 8L6 4"
                                    stroke="#30261D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <TableRow
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <span>
                          data with the filter entered could not be found
                        </span>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )
              ) : (
                <>
                  <TableRow
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <CircularProgress size="sm" sx={{ margin: "0" }} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default CustomTable2;
