import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {
  Chip,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "../../Icons/checkIcon";
import httpService from "../../Services/httpService";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { FormControl } from "@mui/base";
import { CircularProgress } from "@mui/joy";
import toast, { Toaster } from "react-hot-toast";

function AddNewConnector() {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [names, setNames] = useState([]);
  const [productsName, setProductsName] = useState([]);
  const [newConnector, setNewConnector] = useState("");
  const [description, setDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newCountry, setNewCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [address, setAddress] = useState("");
  const [inputFields, setInputFields] = useState([{ id: null, value: "" }]);
  const [idCount, setIdCount] = useState(2);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSelectProductChange = (event) => {
    console.log(event.target);
    const { value } = event.target;
    setSelectedOptions(value);
  };

  const handleSave = () => {
    const countryId =
      newCountry.find((item) => item.code === selectedCountry)?.id || null;

    // const productIds = [];
    // for (let product of names) {
    //   for (let selectProduct of selectedOptions) {
    //     if (product.id === selectProduct) {
    //       productIds.push(product.id);
    //     }
    //   }
    // }

    const productKeywords = inputFields.map((field) => ({
      product: selectedOptions,
      keyword: field.value,
    }));

    const formData = {
      name: newConnector,
      address: address,
      country: countryId,
      description: description,
      website: address,
      page_offset: 0,
      pages_count: 2,
      extract_patterns: "",
      is_active: checked,
      keywords_product: productKeywords,
    };

    httpService
      .post("/connector/", formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Connector created successfully!");
        } else {
          toast.error("Connector Created Failed!");
        }
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error}`);
      });
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      Promise.all([getProducts(), getCountries(), getConnectors()])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  const getProducts = async () => {
    const productsResponse = await httpService.get("/product/");
    const productsData = productsResponse.data.results;
    setNames(productsData.map((item) => ({ id: item.id, name: item.name })));
    return true;
  };
  const getCountries = async () => {
    const countriesResponse = await httpService.get("/country/");
    const countriesData = countriesResponse.data.results;
    setNewCountry(countriesData);
    return true;
  };
  const getConnectors = async () => {
    const response = await httpService.get("/connector/");
    const data = response.data.results;
    const allProducts = data.flatMap((item) =>
      item.products.map((product) => ({
        id: product.id,
        name: product.name,
      }))
    );
    setProductsName(allProducts);
    return true;
  };

  const handleAddInput = () => {
    setInputFields([...inputFields, { id: idCount, value: "" }]);
    setIdCount(idCount + 1);
  };

  const handleRemoveInput = (id) => {
    const values = inputFields.filter((field) => field.id !== id);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="add-new-connector">
            <div>
              <div>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={snackbarOpen}
                  autoHideDuration={4000}
                  onClose={handleSnackbarClose}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="success"
                    onClose={handleSnackbarClose}
                  >
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>

                <Button
                  // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
                  sx={{
                    textTransform: "none",
                    cursor: "pointer",
                    color: "var(--blue)",
                    fontWeight: "bold",
                    fontSize: "1.5em",
                    display: "flex",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={() => history.goBack()}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "3px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                    >
                      <path
                        d="M25.3337 16.5H6.66699"
                        stroke="#64513D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                        stroke="#64513D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  Add new Connector
                </Button>
              </div>
              <div className="add-new-connector__form-container">
                <InputLabel
                  htmlFor="component-simple"
                  sx={{ marginBottom: "1em" }}
                >
                  Main info
                </InputLabel>
                <TextField
                  value={newConnector}
                  onChange={(e) => setNewConnector(e.target.value)}
                  id="outlined-basic"
                  placeholder="Connector name"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "white" }}
                />
                <TextField
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  id="outlined-basic"
                  placeholder="Address (URL)"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    margin: "2em 0",
                    backgroundColor: "white",
                  }}
                />
                <Select
                  sx={{ width: "100%", backgroundColor: "white" }}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  {newCountry.map((country, index) => (
                    <MenuItem key={country.id + index} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <Textarea
                  sx={{ marginTop: "2em", backgroundColor: "white" }}
                  minRows={4}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder="Description (Optional)"
                />
                <FormControlLabel
                  sx={{ margin: "2em 0" }}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Activation"
                />
                <div className="add-new-connector__keyword-container">
                  <span className="add-new-connector__assigned-title">
                    Assign Product(s)
                  </span>
                  {inputFields.map((inputField, index) => (
                    <div
                      key={inputField.id + index}
                      className="add-new-connector__input-field"
                    >
                      <Box
                        sx={{
                          width: "80%",
                          backgroundColor: "var(--lightest-gray)",
                          padding: ".5em",
                          borderRadius: ".5em",
                          margin: ".5em 0",
                        }}
                      >
                        <FormControl fullWidth>
                          <Typography
                            sx={{
                              marginLeft: ".5em",
                              fontSize: ".7em",
                              color: "var(--light-brown)",
                            }}
                          >
                            Product
                          </Typography>
                          <Select
                            value={selectedOptions}
                            onChange={handleSelectProductChange}
                            sx={{
                              width: "100%",
                              marginBottom: "1em",
                              backgroundColor: "white",
                            }}
                          >
                            {productsName.map((name, index) => (
                              <MenuItem key={name.id + index} value={name.id}>
                                {name.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography
                            sx={{
                              marginLeft: ".5em",
                              fontSize: ".7em",
                              color: "var(--light-brown)",
                            }}
                          >
                            Keyword
                          </Typography>
                          <TextField
                            sx={{ width: "100%", backgroundColor: "white" }}
                            value={inputField.value}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                          />
                        </FormControl>
                      </Box>
                      {index !== 0 && (
                        <IconButton
                          sx={{
                            backgroundColor: "var(--lightest-gray) !important",
                            borderRadius: ".2em",
                          }}
                          onClick={() => handleRemoveInput(inputField.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  ))}
                  <IconButton
                    onClick={handleAddInput}
                    sx={{
                      margin: "1em 0",
                      color: "var(--blue)",
                      backgroundColor: "var(--light-blue)",
                      borderRadius: ".2em",
                      width: "100%",
                      border: "dashed .1em var(--blue)",
                      "&:hover": { backgroundColor: "var(--light-blue)" },
                    }}
                  >
                    <AddIcon />
                    <Typography
                      variant="button"
                      component="span"
                      sx={{ textTransform: "none", marginLeft: ".5em" }}
                    >
                      Add keyword
                    </Typography>
                  </IconButton>
                </div>
              </div>
              <div className="add-new-connector__buttons-container">
                <div className="add-new-connector__buttons">
                  <Button
                    onClick={() => history.goBack()}
                    sx={{
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                      padding: ".5em 2.5em",
                      "&:hover": {
                        backgroundColor: "var(--lightest-gray)",
                        color: "var(--darkest-gray)",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    sx={{
                      backgroundColor: "var(--blue)",
                      color: "white",
                      padding: ".5em 3em",
                      "&:hover": {
                        backgroundColor: "var(--blue)",
                        color: "white",
                      },
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default AddNewConnector;
