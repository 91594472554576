import React, { useEffect, useState } from "react";
import "./results.scss";
import { CircularProgress } from "@mui/joy";
import { Divider, Drawer, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";
import CustomTable2 from "../../Components/CustomTable/customTable2";
import { useHistory, useParams, useLocation } from "react-router-dom";
import httpService from "../../Services/httpService";

function Results() {
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hashUrl, setHashUrl] = useState("");
  const [results, setResults] = useState([]);
  const [emailDetail, setEmailDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  const [connectors, setConnectors] = useState([]);
  const [connectorSelected, setConnectorSelected] = useState([]);

  const [countries, setCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);

  const [clusters, setClusters] = useState([]);
  const [clusterSelected, setClusterSelected] = useState([]);

  const [products, setProducts] = useState([]);
  const [productSelected, setProductSelected] = useState([]);

  const [violations, setViolations] = useState([]);
  const [violationSelected, setViolationSelected] = useState([]);

  const [rowsPerPages, setRowsPerPages] = useState(10);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [page, setPage] = useState(0);

  const [tableLoading, setTableLoading] = useState(false);
  const [resultSetData, setResultSetData] = useState(null);

  const [defaultSelectedViolations, setDefaultefaultSelectedViolations] =
    useState([]);

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        if (!hashUrl) return;

        const response = await httpService.get(
          `/result/email-template/${hashUrl}/`
        );

        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
          return;
        }

        const data = response.data;
        setEmailDetail(data);
      } catch (error) {
        console.error("Error fetching email template:", error);
      }
    };

    fetchEmailTemplate();
  }, [hashUrl, httpService, history, setEmailDetail]);

  const sendEmail = async () => {
    try {
      const response = await httpService.post(
        `/result/email-template/${hashUrl}/`
      );

      if (response.status === 200) {
        toast.success("Email Sent Successfully!");
        setDrawerOpen(false);
        const foundResIndex = results.findIndex(
          (res) => res.hashUrl === hashUrl
        );
        if (foundResIndex !== -1) {
          const newRes = [...results];
          newRes[foundResIndex].email_sent = true;
          setResults([...newRes]);
        }
      } else {
        toast.error("Something went wrong. Please try again later!");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred while sending the email.");
    }
  };

  const renderDrawer = () => {
    return (
      <Drawer
        sx={{ width: "40em" }}
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {emailDetail ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: "15px",
                  marginTop: "10px",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Send email to {emailDetail?.connector?.concated}
              </Typography>
              <span
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setDrawerOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M15.75 5L5.75 15"
                    stroke="#64513D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.75 5L15.75 15"
                    stroke="#64513D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <Box
              className="email-history"
              sx={{ width: "auto", padding: "16px" }}
            >
              <div>
                <div className="email-history__date-time-container">
                  <div className="email-history__date-time">
                    <span className="email-history__date">Title</span>
                    <a href={emailDetail?.address}>
                      <Tooltip title={emailDetail?.title}>
                        <>{emailDetail?.title?.substring(0, 30)}...</>
                      </Tooltip>
                    </a>
                  </div>
                  <div className="email-history__time-container">
                    <span className="email-history__time">Country</span>
                    <span>{emailDetail?.connector?.country?.code}</span>
                  </div>
                </div>
                <div className="email-history__detected-container">
                  <div className="email-history__detected">
                    <span className="email-history__detected-title">
                      Product
                    </span>
                    <span>{emailDetail?.product?.name}</span>
                  </div>
                </div>
                <div className="email-history__detected-container">
                  <div className="email-history__detected">
                    <span className="email-history__detected-title">
                      Violations
                    </span>
                    {emailDetail?.latest_violations?.map((vio, index) => (
                      <span
                        key={index}
                        style={{
                          textAlign: "center",
                          backgroundColor: "rgba(214, 65, 35, 0.10)",
                          borderRadius: "20px",
                          padding: "3px 8px",
                          color: "#8F4899",
                        }}
                      >
                        {vio}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <span className="email-history__connectors-detected-title">
                Email text
              </span>
              <div
                style={{ overflow: "scroll" }}
                className="email-history__detected-container"
              >
                <div className="email-history__detections-container">
                  {emailDetail?.email_text ? (
                    <pre>{emailDetail?.email_text}</pre>
                  ) : (
                    <span style={{ color: "red" }}>
                      This connector has not been assigned to an Email template.
                      You need to select a template first.
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Divider />
                <Button
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "black",
                    width: "15vw",
                    display: "flex",
                    marginBottom: "2em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "black",
                    },
                  }}
                  onClick={() => setDrawerOpen(false)}
                >
                  <span style={{ marginTop: "5px", marginRight: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M15.75 5L5.75 15"
                        stroke="#64513D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.75 5L15.75 15"
                        stroke="#64513D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    width: "15vw",
                    display: "flex",
                    marginBottom: "2em",
                    marginLeft: "15px",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                  onClick={sendEmail}
                >
                  <span style={{ marginTop: "5px", marginRight: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1381_930)">
                        <path
                          d="M3.58366 3.33331H16.917C17.8337 3.33331 18.5837 4.08331 18.5837 4.99998V15C18.5837 15.9166 17.8337 16.6666 16.917 16.6666H3.58366C2.66699 16.6666 1.91699 15.9166 1.91699 15V4.99998C1.91699 4.08331 2.66699 3.33331 3.58366 3.33331Z"
                          stroke="#D9F2FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.5837 5L10.2503 10.8333L1.91699 5"
                          stroke="#D9F2FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1381_930">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0.25)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Send Report
                </Button>
              </div>
            </Box>
          </>
        ) : (
          <div className="circular-progress-loader">
            <CircularProgress sx={{ margin: "auto" }} />{" "}
          </div>
        )}
      </Drawer>
    );
  };

  const params = useParams();
  const location = useLocation();
  const queryParams1 = new URLSearchParams(location.search);
  const correlation_id = queryParams1.get("correlation_id");

  useEffect(
    () => {
      const getConnectors = () => {
        httpService
          .get("/connector/")
          .then((response) => {
            const data = response.data.results;
            const withoutDuplicate = [
              ...new Set(data?.map((item) => item.name)),
            ];
            setConnectors(withoutDuplicate);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching connectors:", error);
            setLoading(false);
          });
      };

      const getCountries = () => {
        httpService
          .get("/country/")
          .then((response) => {
            setCountries(response.data.results);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching countries:", error);
            setLoading(false);
          });
      };

      const getClusters = () => {
        httpService
          .get("/cluster/")
          .then((response) => {
            setClusters(response.data.results);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching clusters:", error);
            setLoading(false);
          });
      };

      const getProducts = () => {
        httpService
          .get("/product/")
          .then((response) => {
            setProducts(response.data.results);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
            setLoading(false);
          });
      };

      const getViolations = () => {
        httpService
          .get("/violation/")
          .then((response) => {
            const uniqueViolationNamesWithIdSet = new Set(
              response.data.results.map(
                (item) =>
                  `${item.violation_general_name.id}:${item.violation_general_name.name}`
              )
            );

            const uniqueViolationNamesWithIdArray = [
              ...uniqueViolationNamesWithIdSet,
            ].map((entry) => {
              const [id, name] = entry.split(":");
              return { id, name };
            });
            const vaiola = [];
            uniqueViolationNamesWithIdArray?.map((vi) => vaiola.push(vi.name));
            setViolations(uniqueViolationNamesWithIdArray);
            setDefaultefaultSelectedViolations(vaiola);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching violations:", error);
            setLoading(false);
          });
      };

      getConnectors();
      getCountries();
      getClusters();
      getProducts();
      getViolations();
    },
    [
      // connectorSelected,
      // countrySelected,
      // clusterSelected,
      // productSelected,
      // violationSelected,
      // rowsPerPages,
      // page,
    ]
  );

  let queryParams = "";

  if (connectorSelected.length > 0) {
    queryParams += `&connector=${String(connectorSelected)}`;
  }
  if (countrySelected.length > 0) {
    queryParams += `&country_id=${String(countrySelected)}`;
  }
  if (clusterSelected.length > 0) {
    queryParams += `&cluster_id=${String(clusterSelected)}`;
  }
  if (productSelected.length > 0) {
    queryParams += `&product_id=${String(productSelected)}`;
  }
  if (defaultSelectedViolations.length > 0) {
    queryParams += `&violation=${String(defaultSelectedViolations)}`;
  }
  //  else {
  //   queryParams += `&violation=${String(violationSelected)}`;
  // }
  if (rowsPerPages && rowsPerPages !== 10) {
    queryParams += `&page_size=${rowsPerPages}`;
  }
  if (page && page !== 1) {
    queryParams += `&page=${page}`;
  }

  useEffect(() => {
    if (correlation_id) {
      setTableLoading(true);
      httpService
        .get(
          `/connector-run-log/?correlation_id=${correlation_id}&from=${
            (page + 1) * 10
          }&page=${page + 1}${queryParams}`
        )
        .then(async (response) => {
          if (response.status === 401) {
            localStorage.removeItem("accessToken");
            return history.push("/login");
          }
          const res = await response.data;
          return res;
        })
        .then(async (data) => {
          const results = await Promise.all(
            data?.data?.map(async (result) => {
              const totalAssignees = result.latest_violations.join(", ");
              return {
                tableNames: (
                  <span className="table-names">
                    {result.name} / {result.country.code}
                  </span>
                ),
                totalAssignees,
                assignedViolation: result.product.name,
                title: result.title,
                connectorId: result.connector_id,
                productId: result.product.id,
                hashURl: result.hash_url,
                email_sent: result.email_sent,
              };
            })
          );

          setTotalPageSize(data?.total_data);
          setResults(results);
          setLoading(false);
          setTableLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          setLoading(false);
          setTableLoading(false);
        });
    } else if (violations.length > 0) {
      setTableLoading(true);
      httpService
        .get(
          `/connector-run-log/?from=${(page + 1) * 10}&page=${
            page + 1
          }${queryParams}`
        )
        .then(async (response) => {
          if (response.status === 401) {
            localStorage.removeItem("accessToken");
            return history.push("/login");
          }
          const res = await response.data;
          return res;
        })
        .then(async (data) => {
          const results = await Promise.all(
            data?.data?.map(async (result) => {
              const totalAssignees = result.latest_violations.join(", ");
              return {
                tableNames: (
                  <span className="table-names">
                    {result.name} / {result.country.code}
                  </span>
                ),
                name: result.name,
                country: result.country?.code,
                totalAssignees,
                assignedViolation: result.product.name,
                title: result.title,
                connectorId: result.connector_id,
                productId: result.product.id,
                hashURl: result.hash_url,
                email_sent: result.email_sent,
              };
            })
          );

          setTotalPageSize(data?.total_data);
          setResults(results);
          setLoading(false);
          setTableLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          toast.error(` ${error.message}`);
          setLoading(false);
          setTableLoading(false);
        });
    } else if (violations.length === 0) {
      setTableLoading(true);
    }
  }, [
    connectorSelected,
    countrySelected,
    clusterSelected,
    productSelected,
    defaultSelectedViolations,
    rowsPerPages,
    page,
    correlation_id,
    queryParams,
    violations,
  ]);

  const headCells = [
    {
      id: "connector",
      numeric: false,
      disablePadding: true,
      label: "Connector",
    },
    {
      id: "title",
      numeric: true,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "product",
      numeric: true,
      disablePadding: false,
      label: "Product",
    },
    {
      id: "LatestViolations",
      numeric: true,
      disablePadding: false,
      label: "Latest violations",
    },
    {
      id: "email_sent",
      numeric: false,
      disablePadding: false,
      label: "Email sent",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  useEffect(() => {
    if (correlation_id) {
      const getDataWithCorrelationId = async () => {
        try {
          const response = await httpService.get(
            `/result_set/${correlation_id}`
          );
          setResultSetData(response.data.data);
        } catch (error) {}
      };
      getDataWithCorrelationId();
    }
  }, [correlation_id]);

  if (loading) {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  }

  return (
    <>
      <Toaster />
      {correlation_id
        ? resultSetData && (
            <>
              <p className="rsult-set-corelation-id">
                result set id : {resultSetData?.correlation_id}{" "}
                {/* <span style={{ margin: "0 5px" }}>in</span> */}
                <span
                  style={{
                    margin: "0",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {"("}
                  {resultSetData?.date}
                </span>{" "}
                <span
                  style={{
                    margin: "0",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  {resultSetData?.time}
                  {")"}
                </span>
              </p>
            </>
          )
        : null}
      {results ? (
        <CustomTable2
          headCells={headCells}
          showStatus={false}
          showCountry={false}
          showGeneralName={false}
          showFilter={true}
          data={results}
          totalRows={totalPageSize}
          currentPage={setPage}
          rowsPerPages={setRowsPerPages}
          actions={true}
          showCheckBox={false}
          showModal={drawerOpen}
          setShowModal={setDrawerOpen}
          setHashUrl={setHashUrl}
          sellerNames={connectors}
          sellerNameSelected={setConnectorSelected}
          countries={countries}
          countrySelected={setCountrySelected}
          clusters={clusters}
          clusterSelected={setClusterSelected}
          products={products}
          productSelected={setProductSelected}
          violations={violations}
          violationSelected={setViolationSelected}
          tableLoading={tableLoading}
          setDefaultefaultSelectedViolations={
            setDefaultefaultSelectedViolations
          }
        />
      ) : null}
      {renderDrawer()}
    </>
  );
}

export default Results;
