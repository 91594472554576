import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/joy";
import "./emailHistory.scss";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";
import MainEmailTable from "./MainEmailTable";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "Time",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "Connector",
    numeric: true,
    disablePadding: false,
    label: "Connector",
  },
  {
    id: "Product",
    numeric: true,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "Violations",
    numeric: true,
    disablePadding: false,
    label: "Violations",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sx={{ paddingLeft: "1em" }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EmailHistory(filter) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("calories");
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState(25);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    httpService
      .get(`/email-history?page=${page}`)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        console.log("data=========>", data);
        // settotalRows(data.count);
        // setRows(data.results);
        setResults(Object.values(data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
  }, [page]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await httpService.get('/');
  //       const data = response.data;
  //       setRows(data);
  //       setResults(data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching products:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [history]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [resultDetail, setResultDetail] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDetailClick = (row) => {
    setSelectedRow(row);
    setDrawerOpen(true); // Open the Drawer
  };

  useEffect(() => {
    if (selectedRow) {
      // Assuming your row data properties match the properties in resultDetail
      setResultDetail({
        date: selectedRow.date,
        time: selectedRow.time,
        // Add other properties as needed
      });
    }
  }, [selectedRow]);

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  const renderDrawer = () => (
    <Drawer
      sx={{ width: "40em" }}
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box className="email-history" sx={{ width: "auto", padding: "16px" }}>
        <div className="email-history__date-time-container">
          <div className="email-history__date-time">
            <span className="email-history__date">Date</span>
            <span>{selectedRow?.date}</span>
          </div>
          <div className="email-history__time-container">
            <span className="email-history__time">Time</span>
            <span>{selectedRow?.time}</span>
          </div>
        </div>
        <div className="email-history__detected-container">
          <div className="email-history__detected">
            <span className="email-history__detected-title">Connector</span>
            <span style={{ color: "#007CBA" }}>
              {selectedRow?.connector.country.name}
            </span>
          </div>
        </div>
        <div className="email-history__detected-container">
          <div className="email-history__detected">
            <span className="email-history__detected-title">Product</span>
            <span>{selectedRow?.product.name}</span>
          </div>
        </div>
        <div className="email-history__detected-container">
          <div className="email-history__detected">
            <span className="email-history__detected-title">
              Reported violations via email
            </span>
            <span className="email-history__detected-violations-length">
              {selectedRow?.connector.email_receivers}
            </span>
          </div>
        </div>
        <span className="email-history__connectors-detected-title">
          Email text
        </span>
        <div
          style={{ overflow: "scroll" }}
          className="email-history__detected-container"
        >
          <div className="email-history__detections-container">
            {selectedRow?.text && <pre>{selectedRow?.text}</pre>}
          </div>
        </div>
      </Box>
    </Drawer>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - rows.length;

  return (
    <>
      {!loading ? (
        <>
          <MainEmailTable
            data={results}
            handleDetailClick={handleDetailClick}
          />
          {renderDrawer()}
        </>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
  return (
    <>
      <section>
        {!loading ? (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer sx={{ width: "auto" }}>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Time</TableCell>
                      <TableCell align="left">Connector</TableCell>
                      <TableCell align="left">Product</TableCell>
                      <TableCell align="left">Violations</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            sx={{ paddingLeft: "1em" }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                          >
                            {row.date}
                          </TableCell>
                          <TableCell align="left">{row.time}</TableCell>
                          <TableCell align="left">
                            <span className="connector-name">
                              {row.connector?.concated.split("/")[0]}{" "}
                              <span className="connector-name__split">/</span>
                              {row.connector?.concated.split("/")[1]}
                            </span>
                          </TableCell>
                          <TableCell align="left">{row.product.name}</TableCell>
                          <TableCell align="left">
                            {row.violations?.map((vio, index) => (
                              <span
                                key={index}
                                style={{
                                  backgroundColor: "var(--low-orange)",
                                  color: "var(--dark-orange)",
                                  padding: ".5em",
                                  borderRadius: ".8em",
                                }}
                              >
                                {vio}
                              </span>
                            ))}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              onClick={() => handleDetailClick(row)}
                              sx={{
                                textTransform: "none",
                                color: "#000",
                                backgroundColor: "#F6F5F4",
                                borderRadius: "5px",
                              }}
                            >
                              Detail
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "4px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M6 12L10 8L6 4"
                                    stroke="#30261D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        sx={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              {renderDrawer()}
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default EmailHistory;
