import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Chip,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/joy";
import Textarea from "@mui/joy/Textarea";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { FormControl } from "@mui/base";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CopyIcon from "../../Icons/copyIcon";
import DeleteIcon from "../../Icons/deleteIcon";
import { useHistory, useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "../../Icons/checkIcon";
import XIcon from "../../Icons/xIcon";
import httpService from "../../Services/httpService";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import toast, { Toaster } from "react-hot-toast";

function EditConnector() {
  const history = useHistory();
  const { id } = useParams();
  const [newConnector, setNewConnector] = useState("");
  const [description, setDescription] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [address, setAddress] = useState([]);
  const [country, setCountry] = useState([]);
  const [lastRun, setLastRun] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [products, setProducts] = useState([{ id: 0, name: "" }]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState({});
  const [inputFields, setInputFields] = useState([{ id: 1, value: "" }]);
  const [loading, setLoading] = useState(false);
  const [idCount, setIdCount] = useState(2);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDelete = () => {
    const list = [];
    list.push(parseInt(id, 10));

    httpService
      .post("/connector/bulk_delete/", { ids: list })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bulk delete successful");
          history.push("/category");
        } else {
          toast.error("Error deleting selected connector");
        }
      })
      .catch((error) => {
        toast.error(`Error deleting selected connector: ${error}`);
      });
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountryId(value);
  };

  const handleSelectChange = (index, event) => {
    const { value } = event.target;
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleSave = (saveMode = true) => {
    let productsSelectedIds = [];

    const formData = {
      name: newConnector,
      address: address,
      country: selectedCountryId,
      description: description,
      website: address,
      page_offset: data.page_offset,
      pages_count: data.pages_count,
      extract_patterns: data.extract_patterns,
      is_active: checked,
      keywords_product: productsSelectedIds,
    };

    const endpoint = saveMode ? `/connector/${id}/` : "/connector/";

    httpService({
      method: saveMode ? "put" : "post",
      url: endpoint,
      data: formData,
    })
      .then((response) => {
        const successMessage = saveMode
          ? "Connector changes successfully!"
          : "Connector duplicated successfully!";
        const errorMessage = saveMode
          ? "Connector changes failed!"
          : "Connector duplicated failed!";
        if (response.status === 200 || response.status === 201) {
          toast.success(successMessage);
        } else {
          toast.error(errorMessage);
        }
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error}`);
      });
  };

  const handleRun = () => {
    const payload = { ids: [parseInt(id, 10)] };

    httpService
      .post("/connector/bulk_run/", payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success("connctor started Successfully.");
        } else {
          toast.error("start connector failed!");
        }
      })
      .catch((error) => {
        toast.error(`start connector failed! ${error}`);
      });
  };

  useEffect(() => {
    setLoading(true);
    const fetchProductData = httpService.get("/product/");
    const fetchCountryData = httpService.get("/country/");
    const fetchConnectorData = httpService.get(`/connector/${id}`);

    Promise.all([fetchProductData, fetchCountryData, fetchConnectorData])
      .then(([productResponse, countryResponse, connectorResponse]) => {
        setProducts(
          productResponse.data.results.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
        setCountry(countryResponse.data.results);
        const connectorData = connectorResponse.data;
        const lastRunDate = connectorData.last_run
          ? new Date(connectorData.last_run)
          : null;
        setSelectedOptions(
          connectorData.keywords_product.map((item) => item.keyword)
        );

        setNewConnector(connectorData.name);
        setAddress(connectorData?.address);
        setChecked(connectorData?.is_active);
        setSelectedCountry(connectorData?.country.id);
        setDescription(connectorData?.description);
        setData(connectorData);
        setLastRun(
          connectorData.last_run
            ? `${lastRunDate.getDate()}/${
                lastRunDate.getUTCMonth() + 1
              }/${lastRunDate.getFullYear()} (${lastRunDate.getHours()}:${lastRunDate.getUTCMinutes()})`
            : "There is no last run!"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id]);

  const handleAddInput = () => {
    setInputFields([...inputFields, { id: idCount, product: "", keyword: "" }]);
    setSelectedOptions([...selectedOptions, ""]); // Initially set an empty string for each newly added product
    setIdCount(idCount + 1);
  };

  const handleRemoveInput = (id) => {
    const updatedInputFields = inputFields.filter((field) => field.id !== id);
    const updatedSelectedOptions = selectedOptions.filter(
      (_, index) => index !== id - 1
    );
    setInputFields(updatedInputFields);
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleChangeProduct = (index, event) => {
    const { value } = event.target;
    const updatedInputFields = [...inputFields];
    updatedInputFields[index].product = value;
    setInputFields(updatedInputFields);
  };

  const handleChangeKeyword = (index, event) => {
    const { value } = event.target;
    const updatedInputFields = [...inputFields];
    updatedInputFields[index].keyword = value;
    setInputFields(updatedInputFields);
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };
  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="edit-connector">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <Button
              // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                color: "var(--blue)",
                fontWeight: "bold",
                fontSize: "1.5em",
                display: "flex",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => history.goBack()}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                >
                  <path
                    d="M25.3337 16.5H6.66699"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Edit {newConnector ? newConnector : null}
            </Button>
            <div className="edit-connector__form-container">
              <InputLabel
                htmlFor="component-simple"
                sx={{
                  margin: "1em 0 2em 0",
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                Main info
              </InputLabel>
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "1em", fontSize: "0.8em" }}
              >
                Connector name
              </InputLabel>
              <TextField
                className="edit-connector__text-field"
                value={newConnector}
                onChange={(e) => setNewConnector(e.target.value)}
                id="outlined-basic"
                placeholder="Connector name"
                variant="outlined"
                sx={{ width: "100%", backgroundColor: "white" }}
              />
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "1em", fontSize: "0.8em" }}
              >
                Address (URL)
              </InputLabel>
              <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                id="outlined-basic"
                placeholder="Address (URL)"
                variant="outlined"
                sx={{ width: "100%", margin: "0", backgroundColor: "white" }}
              />
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginTop: "1em", fontSize: "0.8em" }}
              >
                Country
              </InputLabel>
              <Select
                sx={{ width: "100%", backgroundColor: "white" }}
                value={selectedCountryId}
                onChange={handleCountryChange}
              >
                {country.map((countryItem) => (
                  <MenuItem key={countryItem.id} value={countryItem.id}>
                    {countryItem.name}
                  </MenuItem>
                ))}
              </Select>

              <Textarea
                sx={{ marginTop: "2em", backgroundColor: "white" }}
                minRows={4}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder="Update Description (Optional)"
              />
              <FormControlLabel
                sx={{ margin: "2em 0" }}
                labelPlacement="start"
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Activation"
              />
              <div className="add-new-connector__keyword-container">
                <span className="add-new-connector__assigned-title">
                  Assign Products
                </span>
                {inputFields.map((inputField, index) => (
                  <div
                    key={inputField.id}
                    className="add-new-connector__input-field"
                  >
                    <Box
                      sx={{
                        width: "80%",
                        backgroundColor: "var(--lightest-gray)",
                        padding: ".5em",
                        borderRadius: ".5em",
                        margin: ".5em 0",
                      }}
                    >
                      <FormControl fullWidth>
                        <Typography
                          sx={{
                            marginLeft: ".5em",
                            fontSize: ".7em",
                            color: "var(--light-brown)",
                          }}
                        >
                          Product
                        </Typography>
                        <Select
                          value={inputField.product}
                          onChange={(event) =>
                            handleChangeProduct(index, event)
                          }
                          sx={{
                            width: "100%",
                            marginBottom: "1em",
                            backgroundColor: "white",
                          }}
                        >
                          {products.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                              {product.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography
                          sx={{
                            marginLeft: ".5em",
                            fontSize: ".7em",
                            color: "var(--light-brown)",
                          }}
                        >
                          Keyword
                        </Typography>
                        <TextField
                          sx={{ width: "100%", backgroundColor: "white" }}
                          value={inputField.keyword}
                          onChange={(event) =>
                            handleChangeKeyword(index, event)
                          }
                        />
                      </FormControl>
                    </Box>
                    {index !== 0 && (
                      <IconButton
                        sx={{
                          backgroundColor: "var(--lightest-gray) !important",
                          borderRadius: ".2em",
                        }}
                        onClick={() => handleRemoveInput(inputField.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                ))}
                <IconButton
                  onClick={handleAddInput}
                  sx={{
                    margin: "1em 0",
                    color: "var(--blue)",
                    backgroundColor: "var(--light-blue)",
                    borderRadius: ".2em",
                    width: "100%",
                    border: "dashed .1em var(--blue)",
                    "&:hover": { backgroundColor: "var(--light-blue)" },
                  }}
                >
                  <AddIcon />
                  <Typography
                    variant="button"
                    component="span"
                    sx={{ textTransform: "none", marginLeft: ".5em" }}
                  >
                    Add keyword
                  </Typography>
                </IconButton>
              </div>
              <span className="edit-connector__assigned-title">
                Assign products
              </span>
              {/* <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            sx={{ fontSize: ".7em", marginLeft: ".5em" }}
          >
            Violation(S)
          </InputLabel> */}
              <FormControl sx={{ width: 300 }}>
                <Select
                  sx={{ width: "100%", backgroundColor: "white" }}
                  multiple
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected.map((violation, index) => (
                        <Chip
                          key={index}
                          label={violation}
                          onDelete={() =>
                            setSelectedOptions((prevSelected) =>
                              prevSelected.filter(
                                (option) => option !== violation
                              )
                            )
                          }
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          color="primary"
                          variant="filled"
                        />
                      ))}
                    </Stack>
                  )}
                >
                  {products.map((violation) => (
                    <>
                      <MenuItem
                        key={violation.id}
                        value={violation.name}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {violation.name}
                        {selectedOptions.includes(violation.name) ? (
                          <CheckIcon color="info" />
                        ) : null}
                      </MenuItem>
                    </>
                  ))}
                </Select>
              </FormControl>
              <div className="edit-connector__run-handler">
                <p className="edit-connector__status-title">Status</p>
                <div className="edit-connector__last-run-section">
                  <span>Last run:</span>
                  <span>{lastRun}</span>
                </div>
                <div className="edit-connector__status-section">
                  <span>Status</span>
                  <span>
                    {data.status ? (
                      <CheckIcon color="info" />
                    ) : (
                      <XIcon color="info" />
                    )}
                  </span>
                </div>
                <Button
                  onClick={handleRun}
                  sx={{
                    backgroundColor: "var(--dark-green)",
                    color: "white",
                    padding: "0.3em 3em",
                    "&:hover": {
                      backgroundColor: "var(--dark-green)",
                      color: "white",
                    },
                  }}
                >
                  Run
                </Button>
              </div>
              <div>
                <p className="edit-connector__actions-title">Actions</p>
                <Button
                  startIcon={<CopyIcon />}
                  onClick={() => handleSave(false)}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: "0.4em 2em",
                    marginRight: "1em",
                    stroke: "white",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Duplicate
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--pink)",
                    color: "white",
                    padding: "0.4em 2em",
                    "&:hover": {
                      backgroundColor: "var(--pink)",
                      color: "var(--white)",
                    },
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="edit-connector__buttons-container">
              <div className="edit-connector__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSave(true)}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default EditConnector;
