import React, { useEffect, useState } from "react";
import "./emailTemplate.scss";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  Switch,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { CircularProgress } from "@mui/joy";
import EditIcon from "../../Icons/editIcon";
import { Link } from "react-router-dom";
import { Container } from "@mui/joy";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../Icons/searchIcon";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

const useStyles = makeStyles(() => ({
  emailTextCell: {
    width: "45%",
    "& .email-text": {
      maxHeight: "150px",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 6,
      "-webkit-box-orient": "vertical",
    },
    "& .email-ellipsis": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
}));

const EmailTemplate = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        setLoading(true);
        const response = await httpService.get("/email-template/");

        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }

        const data = await response.data.results;
        setData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching email template:", error);
        setLoading(false);
      }
    };

    fetchEmailTemplate();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((item) => item.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDeleteSelected = async () => {
    try {
      const updatedData = data.filter((item) => !selected.includes(item.id));

      const response = await httpService.post("/email-template/bulk_delete/", {
        ids: selected,
      });

      if (response.status === 200) {
        toast.success("Bulk deleted successfully.");
        setData(updatedData);
        setSelected([]);
      } else {
        toast.error("Bulk deleted failed.");
      }
    } catch (error) {
      // console.error("Error deleting selected emails:", error);
      toast.error(`Bulk deleted failed. ${error}`);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((row) => {
    const name = (row.name || "").toLowerCase();
    const email = (row.email || "").toLowerCase();
    const connector =
      typeof row.connector === "string" ? row.connector.toLowerCase() : "";

    return (
      name.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase()) ||
      connector.includes(searchQuery.toLowerCase())
    );
  });

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div>
            <Container
              component="div"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "0 !important",
                marginBottom: "1em",
              }}
            >
              <TextField
                sx={{ width: "25vw" }}
                placeholder="Search"
                variant="outlined"
                value={searchQuery}
                autoComplete="false"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                component={Link}
                to="/email-template/add"
                sx={{
                  width: "fit-content",
                  backgroundColor: "var(--blue)",
                  textTransform: "none",
                  fontSize: "1.2em",
                  color: "white",
                  "&:hover": { backgroundColor: "var(--blue)", color: "white" },
                }}
                startIcon={<AddIcon />}
              >
                Add new template
              </Button>
            </Container>
            {selected.length > 0 && (
              <Button
                sx={{
                  backgroundColor: "var(--pink)",
                  color: "white",
                  width: "15vw",
                  display: "flex",
                  marginBottom: "2em",
                  "&:hover": {
                    backgroundColor: "var(--light-red)",
                    color: "white",
                  },
                }}
                onClick={handleDeleteSelected}
              >
                Delete Selected
              </Button>
            )}
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < data.length
                        }
                        checked={selected.length === data.length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell sx={{ width: "45%" }}>Email Text</TableCell>
                    <TableCell>Connector</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(row.id)}
                          onClick={(event) =>
                            handleCheckboxClick(event, row.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell
                        sx={{ width: "45%" }}
                        className={classes.emailTextCell}
                      >
                        <div className="email-text">
                          {row.text}
                          {/*<span className="email-ellipsis"> ...</span>*/}
                        </div>
                      </TableCell>
                      <TableCell>
                        {row.connector.map((connector) => (
                          <span key={connector.id} className="connector-name">
                            {/* {connector.concated} */}
                            {connector?.concated.split("/")[0]}{" "}
                            <span className="connector-name__split">/</span>
                            {connector?.concated.split("/")[1]}
                          </span>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Container
                          sx={{ display: "flex", alignItems: "center" }}
                          component="div"
                        >
                          <IconButton
                            sx={{
                              backgroundColor: "var(--blue)",
                              borderRadius: "20%",
                              a: { height: "1em" },
                              "&:hover": {
                                backgroundColor: "var(--blue)",
                              },
                            }}
                            aria-label="edit"
                            size="small"
                          >
                            <Link to={`/email-template/edit/${row.id}`}>
                              <EditIcon fontSize="inherit" />
                            </Link>
                          </IconButton>
                          <Switch checked={row.is_active} />
                        </Container>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
};

export default EmailTemplate;
