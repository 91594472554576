import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import SelectDate from "./SelectDate";
import TableConnector from "./TableConnector";
import BarChart from "./BarChart";
import BarChart2 from "./BarChart2";
import AreaChart from "./AreaChart";
import { CircularProgress } from "@mui/joy";
import GeoChart from "./GeoChart";
import httpService from "../../Services/httpService";
import Dount from "./DounotChart";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { DateRangeIcon } from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import DashboardMapSvg from "../../Components/Dashboard/DashboardMapSvg/DashboardMapSvg";

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [topViolationChart, setTopViolationChart] = useState([]);
  const [dountChartData, setDountChartData] = useState([]);
  const [dountChartDataLabels, setDountChartDataLables] = useState([]);
  const [countries, setCountries] = useState([]);
  const [violationsOverTime, setViolationsOverTime] = useState([]);
  const [chartCountries, setChartCountries] = useState([]);
  const [country, setCountry] = useState("UAE");
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dataForMap, setDataForMap] = useState();
  function formatDateString(inputDateString) {
    var dateObject = new Date(inputDateString);
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth() + 1;
    var day = dateObject.getDate();
    var formattedDate =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    return formattedDate;
  }
  const toggle = () => {
    setOpen(!open);
  };

  const setDate = async (range) => {
    setFrom(await formatDateString(range.startDate));
    setTo(await formatDateString(range.endDate));
    toggle();
  };

  function getCurrentDateTime() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var day = currentDate.getDate();
    var formattedDateTime =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);

    return formattedDateTime;
  }

  function getPastMonthDateTime() {
    var today = new Date();
    var oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);
    var formattedDate = oneMonthAgo.toISOString().slice(0, 10);
    return formattedDate;
  }

  useEffect(() => {
    setLoading(true);
    httpService
      .get(`/dashboard/?country=${country}&from=${from}&to=${to}`)
      // .get(
      //   "https://prod-nestle-dashboard-api.darkube.app/dashboard/v1/dashboard/"
      // )
      // .get(`/dashboard/?country=${country}`)
      // &from=2023-01-12&to=2023-02-12
      .then((response) => {
        setDataForMap(response.data);
        return response.data;
      })
      .then((data) => {
        const countriess = [];
        const firstData = ["Country", "violations"];
        setViolationsOverTime(data?.violations_over_time);
        setPieChartData(data?.violation_per_cluster_chart);
        const sortedData = data?.violation_per_country_cluster_chart.sort(
          (a, b) => {
            let violationsA = a.value.countries.reduce(
              (total, country) => total + country.violations,
              0
            );
            let violationsB = b.value.countries.reduce(
              (total, country) => total + country.violations,
              0
            );
            return violationsB - violationsA;
          }
        );
        setBarChartData(sortedData);
        data?.violation_per_country_cluster_chart.map((country) => {
          country.value.countries.map((coun) => {
            countriess.push([
              coun.country_name?.toLowerCase().replace("kingdom of ", ""),
              coun.violations,
            ]);
          });
        });

        countriess.unshift(firstData);
        setChartCountries(countriess);
        setTopViolationChart(data?.top_violations_chart);
        const dountChartDatas = [];
        data?.violation_per_cluster_chart.map((info) => {
          return dountChartDatas.push(info.value);
        });
        setDountChartData(dountChartDatas);
        const dountLabels = [];
        data?.violation_per_cluster_chart.map((info) => {
          return dountLabels.push(info.key);
        });
        setDountChartDataLables(dountLabels);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching names:", error);
        setLoading(false);
      });

    // httpService
    //   .get(`/dashboard/`)
    //   // .get(
    //   //   "https://prod-nestle-dashboard-api.darkube.app/dashboard/v1/dashboard/"
    //   // )
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .then((data) => {
    //     const countriess = [];
    //     const firstData = ["Country", "violations"];
    //     setViolationsOverTime(data?.violations_over_time);
    //     setPieChartData(data?.violation_per_cluster_chart);
    //     const sortedData = data?.violation_per_country_cluster_chart.sort(
    //       (a, b) => {
    //         let violationsA = a.value.countries.reduce(
    //           (total, country) => total + country.violations,
    //           0
    //         );
    //         let violationsB = b.value.countries.reduce(
    //           (total, country) => total + country.violations,
    //           0
    //         );
    //         return violationsB - violationsA;
    //       }
    //     );
    //     setBarChartData(sortedData);
    //     sortedData?.map((country) => {
    //       country.value.countries.map((coun) => {
    //         countriess.push([
    //           coun.country_name.replace("Kingdom of ", ""),
    //           coun.violations,
    //         ]);
    //       });
    //     });
    //     countriess.unshift(firstData);
    //     setChartCountries(countriess);
    //     setTopViolationChart(data?.top_violations_chart);
    //     data?.violation_per_cluster_chart.map((info) => {
    //       return dountChartData.push(info.value);
    //     });
    //     data?.violation_per_cluster_chart.map((info) => {
    //       return dountChartDataLabels.push(info.key);
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching names:", error);
    //     setLoading(false);
    //   });
    // setLoading(true);
    httpService
      .get(`/country`)
      .then((response) => {
        return response.data.results;
      })
      .then((data) => {
        setCountries(data);

        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching names:", error);
        // setLoading(false);
      });
  }, [country, from, to]);

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        <CircularProgress sx={{ margin: "auto" }} />
      </div>
    );
  };

  return (
    <>
      <section>
        {!loading ? (
          <div>
            <div className="date-section">
              <OutlinedInput
                type="text"
                placeholder="Date Range"
                value={`From ${from ? from : getPastMonthDateTime()} - To ${
                  to ? to : getCurrentDateTime()
                }`}
                sx={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  marginRight: "1rem",
                  width: "20rem",
                }}
                onClick={toggle}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <DateRangeIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <section className="date-picker-section">
                <DateRangePicker
                  open={open}
                  toggle={toggle}
                  onChange={(range) => setDate(range)}
                />
              </section>
            </div>
            <h3 className="header-title">Heatmap of countries</h3>
            <div className="country-section">
              {!loading ? (
                <>
                  <div className="county-table">
                    <div className="country-table__header">
                      <span>Country</span>
                      <span>NO. of violations</span>
                    </div>
                    {barChartData[0]?.value.countries.length > 0 ? (
                      <div
                        className="country-table__content"
                        style={{
                          background: `#FCF2F0`,
                          borderLeft: `5px solid #E57C67`,
                        }}
                      >
                        <div className="country-table__content-text">
                          {barChartData[0]?.value?.countries.map((country) => {
                            return (
                              <div
                                key={`0${country.country_code}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: "3px" }}>
                                  {country.country_name}
                                </span>
                                <span style={{ marginRight: "1em" }}>
                                  {country.violations}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {barChartData[1]?.value.countries.length > 0 ? (
                      <div
                        className="country-table__content"
                        style={{
                          background: `#FEFAF2`,
                          borderLeft: `5px solid #F2D27E`,
                        }}
                      >
                        <div className="country-table__content-text">
                          {barChartData[1]?.value?.countries.map((country) => {
                            return (
                              <div
                                key={`1${country.country_code}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: "3px" }}>
                                  {country.country_name}
                                </span>
                                <span style={{ marginRight: "1em" }}>
                                  {country.violations}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {barChartData[2]?.value.countries.length > 0 ? (
                      <div
                        className="country-table__content"
                        style={{
                          background: `#FDFDF2`,
                          borderLeft: `5px solid #EEF07A`,
                        }}
                      >
                        <div className="country-table__content-text">
                          {barChartData[2]?.value?.countries.map((country) => {
                            return (
                              <div
                                key={`2${country.country_code}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: "3px" }}>
                                  {country.country_name}
                                </span>
                                <span style={{ marginRight: "1em" }}>
                                  {country.violations}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {barChartData[3]?.value.countries.length > 0 ? (
                      <div
                        className="country-table__content"
                        style={{
                          background: `#F8FBF3`,
                          borderLeft: `5px solid #BBDB83`,
                        }}
                      >
                        <div className="country-table__content-text">
                          {barChartData[3]?.value?.countries.map((country) => {
                            return (
                              <div
                                key={`3${country.country_code}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: "3px" }}>
                                  {country.country_name}
                                </span>
                                <span style={{ marginRight: "1em" }}>
                                  {country.violations}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {barChartData[4]?.value.countries.length > 0 ? (
                      <div
                        className="country-table__content"
                        style={{
                          background: `#F8FBF3`,
                          borderLeft: `5px solid #BBDB83`,
                        }}
                      >
                        <div className="country-table__content-text">
                          {barChartData[4]?.value?.countries.map((country) => {
                            return (
                              <div
                                key={`4${country.country_code}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: "3px" }}>
                                  {country.country_name}
                                </span>
                                <span style={{ marginRight: "1em" }}>
                                  {country.violations}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="country-map">
                    {/* map svg */}

                    <DashboardMapSvg data={chartCountries} />

                    {/* end of map svg */}
                  </div>
                </>
              ) : null}
            </div>
            <h3 className="header-title">Violations per clusters</h3>
            <div className="violation-cluster">
              <div className="violation-pie-cluster">
                {pieChartData.length > 0 ? (
                  <>
                    <div className="violation-pie-cluster__list">
                      <div className="country-table__header">
                        <span>Cluster</span>
                        <span>NO. of violations</span>
                      </div>
                      <div className="cluster-table">
                        {pieChartData &&
                          pieChartData?.map((data) => (
                            <div
                              key={data?.key}
                              className="cluster-table__section"
                            >
                              <span>{data?.key}</span>
                              <span>{data?.value}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="violation-pie-cluster__chart">
                      <Dount
                        data={pieChartData}
                        chartData={dountChartData}
                        labels={dountChartDataLabels}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3>loading...</h3>
                  </div>
                )}
              </div>
              <div className="violation-bar-cluster">
                {pieChartData.length > 0 ? (
                  <BarChart2 data={pieChartData} />
                ) : (
                  <div>
                    <h1>loading...</h1>
                  </div>
                )}
              </div>
            </div>
            <h3 className="header-title">Violations per time (Last 30 days)</h3>
            {!loading ? (
              <div className="violation-overtime-cluster">
                <div className="violation-overtime-pie-cluster">
                  <SelectDate
                    width="100%"
                    label="Country"
                    data={countries}
                    country={country}
                    changeCountry={setCountry}
                  />
                  <div
                    className="country-table__header"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="cluster-table__section-text">Time</span>
                    <span className="cluster-table__section-text2">
                      NO. of violations
                    </span>
                    <span className="cluster-table__section-text2">
                      NO. of resolveds
                    </span>
                  </div>
                  <div className="cluster-table">
                    {violationsOverTime?.map((violation) => {
                      return (
                        <div className="cluster-table__section">
                          <span className="cluster-table__section-text">
                            Week {violation?.week}
                          </span>
                          <span className="cluster-table__section-text2">
                            {violation?.value.violations}
                          </span>
                          <span className="cluster-table__section-text2">
                            {violation?.value.resolved}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="violation-overtime-bar-cluster">
                  <AreaChart data={violationsOverTime} />
                </div>
              </div>
            ) : null}

            <h3 className="header-title">Resellers </h3>
            <div className="connector-section">
              <div className="connector-section__container">
                <p className="header-connector">
                  Top 10 most violated resellers
                </p>
                <div className="connector-section__chart">
                  {topViolationChart.length > 0 ? (
                    <BarChart data={topViolationChart} />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>loading...</h1>
                    </div>
                  )}{" "}
                </div>
                <div className="connector-section__table">
                  {topViolationChart.length > 0 ? (
                    <TableConnector data={topViolationChart} />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>loading</h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default Dashboard;
