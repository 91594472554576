import React, { useState, useEffect } from "react";
import "../../Shared/Colors.scss";
import { InputLabel, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/joy";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/base";
import DeleteIcon from "../../Icons/deleteIcon";
import CopyIcon from "../../Icons/copyIcon";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Textarea from "@mui/joy/Textarea";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

function EditViolation() {
  const { id } = useParams();
  const history = useHistory();
  const [violationName, setViolationName] = useState("");
  const [names, setNames] = useState([]);
  const [selectedGeneralName, setSelectedGeneralName] = useState({
    id: 0,
    name: "",
  });
  const [related, setRelated] = useState({ count: 0, categories: [] });
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDelete = () => {
    setLoading(true);
    httpService
      .delete(`/violation/${id}/`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Delete successful");
          history.push("/violations");
        } else {
          console.error("Error deleting violation");
          toast.error("Delete Failed.");
        }
        setLoading(false);
        history.goBack();
      })
      .catch((error) => {
        console.error("Error deleting violation:", error);
        toast.error(`Error deleting violation: ${error}`);
        setLoading(false);
      });
  };

  const handleDuplicate = () => {
    const formData = {
      violation_specific_name: data.violation_specific_name,
      violation_general_name: data.violation_general_name.id,
      is_active: data.is_active,
      description: data.description,
    };
    setLoading(true);

    httpService
      .post("/violation/", formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Violation duplicated successfully!");
        } else {
          toast.error("Create Violation failed!");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error sending data to API:", error);
        toast.error(`Create Violation failed! ${error}`);
        setLoading(false);
      });
  };

  const handleSelectChange = (event) => {
    const value = names.filter((item) => item.id === event.target.value)[0];
    setSelectedGeneralName(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const generalNamesResponse = await httpService.get(
          "/general-name-violation/"
        );
        const generalNamesData = generalNamesResponse.data.results;
        setNames(
          generalNamesData.map((item) => ({ id: item.id, name: item.name }))
        );
        const violationResponse = await httpService.get(`/violation/${id}/`);
        const violationData = violationResponse.data;
        setDescription(violationData.description);
        setIsActive(violationData.is_active);
        setViolationName(violationData.violation_specific_name);
        setSelectedGeneralName(violationData.violation_general_name);
        setRelated(violationData.related_categories);
        setData(violationData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSave = () => {
    const formData = {
      violation_specific_name: violationName,
      violation_general_name: selectedGeneralName.id,
      is_active: isActive,
      description: description,
    };
    setLoading(true);
    httpService
      .put(`/violation/${id}/`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Violation changes successfully!");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error}`);
        setLoading(false);
      });
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="edit-violation">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <Button
              // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                color: "var(--blue)",
                fontWeight: "bold",
                fontSize: "1.5em",
                display: "flex",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => history.goBack()}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                >
                  <path
                    d="M25.3337 16.5H6.66699"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Edit Violation
            </Button>
            <div className="edit-violation__form-container">
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginBottom: "1em" }}
              >
                Main info
              </InputLabel>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{ fontSize: ".7em", marginLeft: ".5em" }}
              >
                General name
              </InputLabel>
              <FormControl sx={{ width: 300 }}>
                <Select
                  onChange={handleSelectChange}
                  sx={{ width: "100%", backgroundColor: "white" }}
                  value={selectedGeneralName?.id}
                >
                  {names.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="edit-violation__text-field-container">
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{ fontSize: ".7em", marginLeft: ".5em" }}
                >
                  Specific name (Optional)
                </InputLabel>
                <TextField
                  placeholder="Edit Violation Name"
                  value={violationName}
                  onChange={(e) => setViolationName(e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "white" }}
                />
              </div>
              <div className="edit-violation__text-field-container">
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{ fontSize: ".7em", marginLeft: ".5em" }}
                >
                  Description
                </InputLabel>
                <Textarea
                  sx={{ marginTop: "2em" }}
                  minRows={4}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder="Update Description (Optional)"
                />
              </div>
              <FormControlLabel
                sx={{ margin: "2em 0" }}
                labelPlacement="start"
                control={
                  <Switch
                    checked={isActive}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Activation"
              />
              <div>
                <p className="edit-violation__actions-title">Actions</p>
                <Button
                  startIcon={<CopyIcon />}
                  onClick={handleDuplicate}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: "0.4em 2em",
                    marginRight: "1em",
                    stroke: "white",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Duplicate
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--pink)",
                    color: "white",
                    padding: "0.4em 2em",
                    "&:hover": {
                      backgroundColor: "var(--pink)",
                      color: "var(--white)",
                    },
                  }}
                >
                  Delete
                </Button>
              </div>
              <div className="edit-violation__assigned-categories">
                <span className="edit-violation__assigned-categories-title">
                  Assigned to {related.count} categories
                </span>
                <div className="edit-violation__assignees-container">
                  {related.categories?.map((category, index) => (
                    <span
                      key={index}
                      className="edit-category-container__assignees-items"
                    >
                      {category.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="edit-violation__buttons-container">
              <div className="edit-violation__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default EditViolation;
