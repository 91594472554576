import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/dashboard/v1`;
// const apiUrl = `https://prod-nestle-dashboard-api.darkube.app/dashboard/v1`;

const httpService = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

httpService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      // setTimeout(() => {
      //   window.location.href = "/login";
      // }, 2000);
    }
    return Promise.reject(error);
  }
);

export default httpService;
