import React, { useState, useEffect } from "react";
import "./customTable.scss";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, Switch, TextField, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../Icons/searchIcon";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import EditIcon from "../../Icons/editIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import CheckIcon from "../../Icons/checkIcon";
import XIcon from "../../Icons/xIcon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextFields } from "@mui/icons-material";

function CustomTable({
  addButtonText,
  editPath = "/",
  addPath = "/",
  data,
  headCells,
  onBulkDelete,
  onBulkRun,
  tooltipContent,
  showGeneralName,
  onEditClick,
  showStatus,
  showLastRun = false,
  showCountry,
  showSearchInput,
  showFilter,
  actions,
  showCheckBox,
  showSearch,
  showActions = true,
  showModal,
  setShowModal,
  setHashUrl,
}) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [itemsToDelete, setItemsToDelete] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [sellerName, setSellerName] = React.useState("");
  const [country, setCountry] = React.useState([]);
  const [cluster, setCluster] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [violation, setViolation] = React.useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data) {
      setRows(data);
      setTotalRows(data.length);
    }
  }, [data]);

  const handleEditButtonClick = (id, tableNames) => {
    if (onEditClick) {
      onEditClick(id, tableNames);
    }
  };

  const renderEditButton = (id) => (
    <IconButton
      onClick={() => handleEditButtonClick(id)}
      sx={{
        backgroundColor: "var(--blue)",
        borderRadius: "20%",
        padding: "0 10px",
        a: { height: "1em" },
        "&:hover": {
          backgroundColor: "var(--blue)",
        },
      }}
      aria-label="edit"
      size="small"
    >
      <Link to={`${editPath}/${id}`}>
        <EditIcon fontSize="inherit" />
      </Link>
    </IconButton>
  );

  const handleBulk = (onDelete = true) => {
    const updatedRows = rows.filter(
      (row) => !selected.includes(row.tableNames)
    );
    setItemsToDelete([...selected]);
    setSelected([]);
    setRows(updatedRows);
    const selectedIdsForDeletion = selected.map((selectedItem) => {
      const matchingRow = rows.find((row) => row.id === selectedItem);
      return matchingRow.id;
    });
    if (onBulkDelete && onDelete) {
      onBulkDelete(selectedIdsForDeletion);
    } else if (onBulkRun && !onDelete) {
      onBulkRun(selectedIdsForDeletion);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (selected.length === 0) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
    }
    setSelected([]);
  };

  const handleEmailClick = (hashUrl) => {
    setHashUrl(hashUrl);
    setShowModal(true);
  };

  const handleClick = (event, tableId) => {
    const selectedIndex = selected.indexOf(tableId);
    let newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(tableId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
    setItemsToDelete(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (tableId) => selected.indexOf(tableId) !== -1;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter((row) => {
    const searchFields = [
      row.tableNames ? row.tableNames.toString() : "",
      row.assignedViolation ? row.assignedViolation.toString() : "",
      row.product ? row.product.toString() : "",
    ];
    return searchFields.some((field) =>
      field.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  function convertDate(dateToConvert) {
    const date = new Date(dateToConvert);
    return dateToConvert
      ? `${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()}`
      : "";
  }

  function convertTime(inputISODateTime) {
    const inputDate = new Date(inputISODateTime);

    const hours = inputISODateTime
      ? inputDate.getHours().toString().padStart(2, "0")
      : "";
    const minutes = inputISODateTime
      ? inputDate.getUTCMinutes().toString().padStart(2, "0")
      : "";

    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }

  return (
    <Box className="custom-table" sx={{ width: "100%" }}>
      {showSearch && (
        <Box
          sx={{
            padding: "1em",
            borderRadius: ".7em",
            border: "solid lightgray .1em",
            marginBottom: "1em",
          }}
        >
          <Toolbar
            disableGutters={true}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker label="Basic date picker" />
              </DemoContainer>
            </LocalizationProvider>
          </Toolbar>
        </Box>
      )}
      {showFilter ? (
        <Box
          sx={{
            padding: "1em",
            borderRadius: ".7em",
            border: "solid lightgray .1em",
            margin: "2em 0 1em 0",
          }}
        >
          <Toolbar
            disableGutters={true}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{ backgroundColor: "white" }}
                  label="Basic date picker"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Toolbar>
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Seller name
                </InputLabel>
                <Select
                  sx={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sellerName}
                  label="Seller name"
                  onChange={(event) => {
                    setSellerName(event.target.value);
                  }}
                >
                  <MenuItem value="text1">text1</MenuItem>
                  <MenuItem value="text2">text2</MenuItem>
                  <MenuItem value="text3">text3</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  sx={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={country}
                  label="Country"
                  onChange={(event) => {
                    setCountry(event.target.value);
                  }}
                >
                  <MenuItem value="text1">text1</MenuItem>
                  <MenuItem value="text2">text2</MenuItem>
                  <MenuItem value="text3">text3</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cluster</InputLabel>
                <Select
                  sx={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cluster}
                  label="Cluster"
                  onChange={(event) => {
                    setCluster(event.target.value);
                  }}
                >
                  <MenuItem value="text1">text1</MenuItem>
                  <MenuItem value="text2">text2</MenuItem>
                  <MenuItem value="text3">text3</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                <Select
                  sx={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={product}
                  label="Product"
                  onChange={(event) => {
                    setProduct(event.target.value);
                  }}
                >
                  <MenuItem value="text1">text1</MenuItem>
                  <MenuItem value="text2">text2</MenuItem>
                  <MenuItem value="text3">text3</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "19%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Violation</InputLabel>
                <Select
                  sx={{ backgroundColor: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={violation}
                  label="Violation"
                  onChange={(event) => {
                    setViolation(event.target.value);
                  }}
                >
                  <MenuItem value="text1">text1</MenuItem>
                  <MenuItem value="text2">text2</MenuItem>
                  <MenuItem value="text3">text3</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Toolbar>
        </Box>
      ) : (
        showSearchInput && (
          <Toolbar
            sx={{
              display: "flex",
              width: "100%",
              padding: "0 !important",
              marginBottom: "2em",
              justifyContent: "space-between",
            }}
          >
            <TextField
              sx={{ width: "25vw", backgroundColor: "white" }}
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {addButtonText && (
              <Button
                component={Link}
                to={addPath}
                sx={{
                  width: "fit-content",
                  backgroundColor: "var(--blue)",
                  textTransform: "none",
                  fontSize: "1.2em",
                  color: "white",
                  "&:hover": { backgroundColor: "var(--blue)", color: "white" },
                }}
                startIcon={<AddIcon />}
              >
                {addButtonText}
              </Button>
            )}
          </Toolbar>
        )
      )}
      <div className="custom-table__bulk-delete-button">
        {selected.length > 0 && (
          <Button
            sx={{
              backgroundColor: "var(--pink)",
              color: "white",
              width: "15vw",
              display: "flex",
              marginBottom: "2em",
              "&:hover": {
                backgroundColor: "var(--light-red)",
                color: "white",
              },
            }}
            onClick={() => handleBulk(true)}
          >
            Delete selected
          </Button>
        )}

        {onBulkRun && selected.length > 0 && (
          <Button
            sx={{
              backgroundColor: "var(--dark-green)",
              color: "white",
              width: "15vw",
              display: "flex",
              marginBottom: "2em",
              "&:hover": { backgroundColor: "var(--green)", color: "white" },
            }}
            onClick={() => handleBulk(false)}
          >
            Run selected
          </Button>
        )}
      </div>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
              <TableRow>
                <TableCell padding="checkbox">
                  {showCheckBox && (
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < filteredRows.length
                      }
                      checked={
                        filteredRows.length > 0 &&
                        selected.length === filteredRows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  )}
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.numeric ? "center" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        borderBottom: ".1em",
                        borderBottomColor: "lightgrey",
                        borderStyle: "solid",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <TableCell
                        sx={{ borderBottom: "none" }}
                        padding="checkbox"
                      >
                        {showCheckBox && (
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "none" }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        {showGeneralName && (
                          <span className="custom-table__general-name">
                            {row.generalName}
                          </span>
                        )}
                        <span className="custom-table__general-name2">
                          {row.tableNames}
                        </span>
                        {showCountry && (
                          <span className="custom-table__connector-country">
                            / {row.connectorCountry}
                          </span>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1em",
                          borderBottom: "none",
                        }}
                        align="center"
                      >
                        {row.assignedViolation &&
                          row.assignedViolation.map((item, index) => {
                            return index < 3 && item;
                          })}
                        {row.assignedViolation &&
                          row.assignedViolation.length > 3 && (
                            <span className="custom-table__general-name-span">
                              {`+${row.assignedViolation.length - 3}`}
                            </span>
                          )}
                      </TableCell>
                      {showStatus && (
                        <TableCell sx={{ borderBottom: "none" }} align="center">
                          {row.status ? <CheckIcon /> : <XIcon />}
                        </TableCell>
                      )}
                      {showLastRun && (
                        <TableCell sx={{ borderBottom: "none" }} align="center">
                          {convertDate(row.lastRun)}
                          <br />
                          {convertTime(row.lastRun)}
                        </TableCell>
                      )}
                      <TableCell sx={{ borderBottom: "none" }} align="center">
                        {row.totalAssignees}
                      </TableCell>
                      {actions ? (
                        <TableCell align="center">
                          <Button
                            sx={{
                              backgroundColor: `${
                                row.totalAssignees.length === 0
                                  ? "gray"
                                  : "var(--blue)"
                              }`,
                              cursor: `${
                                row.totalAssignees.length === 0
                                  ? "not-allowed"
                                  : "pointer"
                              }`,
                              color: "white",
                              "&:hover": {
                                backgroundColor: "var(--blue)",
                                color: "white",
                              },
                            }}
                            onClick={() => handleEmailClick(row.hashURl)}
                            disabled={
                              row.totalAssignees.length === 0 ? true : false
                            }
                          >
                            Email
                          </Button>
                          <Link to={`/results/detail/${row.hashURl}`}>
                            <Button
                              sx={{
                                backgroundColor: "#F6F5F4",
                                color: "black",
                                marginLeft: "1em",
                                "&:hover": {
                                  backgroundColor: "#F6F5F4",
                                  color: "black",
                                },
                              }}
                            >
                              Detail
                            </Button>
                          </Link>
                        </TableCell>
                      ) : (
                        showActions && (
                          <TableCell
                            sx={{
                              alignItems: "left",
                              display: "flex",
                              justifyContent: "center",
                              borderBottom: "none",
                            }}
                          >
                            <Tooltip
                              onClick={() =>
                                showGeneralName &&
                                renderEditButton(row.id, row.tableNames)
                              }
                              title="Edit"
                            >
                              {renderEditButton(row.id)}
                            </Tooltip>
                            <Tooltip title="Toggle">
                              <Switch checked={row.is_active} />
                            </Tooltip>
                            {/* <Tooltip
                              title={
                                tooltipContent[row.id] ||
                                "THIS ITEM HAVE NOT DESCRIPTION"
                              }
                            >
                              <ErrorOutlineIcon
                                sx={{
                                  color: "var(--blue)",
                                  backgroundColor: "var(--light-blue)",
                                  padding: "0.1em",
                                  width: ".7em",
                                  height: ".7em",
                                  borderRadius: "20%",
                                }}
                              />
                            </Tooltip> */}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default CustomTable;
