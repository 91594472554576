import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./Components/Auth/loginPage";
import Layout from "./Components/Layout/layout";
import Product from "./Pages/Products/product";
import Categories from "./Pages/Categories/categories";
import { QueryClient, QueryClientProvider } from "react-query";
import Violations from "./Pages/Violations/violations";
import Connectors from "./Pages/Connectors/connectors";
import Results from "./Pages/Results/results";

const queryClient = new QueryClient();

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [selectedViolation, setSelectedViolation] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState(null);

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("loggedIn");
    if (storedLoginStatus === "true") {
      setLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem("loggedIn", "true");
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage onLogin={handleLogin} />
          </Route>
          <Route path="/">
            <Layout loggedIn={loggedIn} onLogout={handleLogout}>
              <Route path="/categories" component={Categories} />
              <Route path="/products" component={Product} />
              <Route path="/connectors" component={Connectors} />
              <Route path="/results" component={Results} />
              <Route path="/violations" component={Violations} />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
