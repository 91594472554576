import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./avatarDetail.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";

const AvatarDetail = () => {
  const [avatarEl, setAvatarEl] = useState(null);
  const [notifyEl, setNotifyEl] = useState(null);
  const [username, setUsername] = useState("");
  const history = useHistory();

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const handleNotifyClose = () => {
    setNotifyEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    history.push("/login");
  };

  const open = Boolean(avatarEl);
  const id = open ? "simple-popover" : undefined;

  const notifyOpen = Boolean(notifyEl);
  const notifyId = notifyOpen ? "simple-notify" : undefined;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpService.get("/users");
        const data = response.data;
        setUsername(data.username);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="avatar-detail">
      <Stack direction="row" spacing={1}>
        <Button
          sx={{ textTransform: "none" }}
          aria-describedby={id}
          onClick={handleAvatarClick}
        >
          <Avatar>{username ? username[0] : "A"}</Avatar>
          <span className="avatar-detail__profile-name">
            {username ? username : ""}
          </span>
          <KeyboardArrowDownIcon sx={{ color: "var(--light-brown)" }} />
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ width: "15vw" }} disablePadding>
          {/* <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Setting"/>
                        </ListItemButton>
                    </ListItem>
                    <Divider/> */}
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <Popover
        id={notifyId}
        open={notifyOpen}
        anchorEl={notifyEl}
        onClose={handleNotifyClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Avatar" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Favorites" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default AvatarDetail;
