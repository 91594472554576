// import React, { useState } from "react";
// import { useEffect } from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ data }) => {
//   console.log("area chart", data);
//   const chartOptions = {
//     chart: {
//       id: "line-chart",
//     },
//     xaxis: {
//       categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
//     },
//   };

//   const [chartSeries, setChartSeries] = useState([]);

//   useEffect(() => {
//     const chartData = [
//       {
//         name: "Violations",
//         data: [],
//       },
//       {
//         name: "Resolved",
//         data: [],
//       },
//     ];
//     data?.map((chart) => {
//       chartData[0].data.push(chart.value.violations);
//       chartData[1].data.push(chart.value.resolved);
//     });
//     setChartSeries(chartData);
//   }, [data]);

//   return (
//     <>
//       {chartSeries?.length > 0 ? (
//         <Chart
//           options={chartOptions}
//           series={chartSeries}
//           type="line"
//           height={250}
//           width={650}
//         />
//       ) : null}
//     </>
//   );
// };

// export default AreaChart;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = ({ data }) => {
  const chartData = {
    series: [
      {
        name: "Violations",
        data: [],
      },
      {
        name: "Resolved",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    const chartData = [
      {
        name: "Violations",
        data: [],
      },
      {
        name: "Resolved",
        data: [],
      },
    ];
    data?.map((chart) => {
      chartData[0].data.push(chart.value.violations);
      chartData[1].data.push(chart.value.resolved);
    });
    setChartSeries(chartData);
  }, [data]);

  return (
    <div id="chart" style={{ width: "100%", height: "100%" }}>
      <ReactApexChart
        options={chartData.options}
        series={chartSeries}
        height={"100%"}
        type="bar"
      />
    </div>
  );
};

export default AreaChart;
