import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/joy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "./resultSet.scss";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";

function createData(
  date,
  time,
  total_connectors,
  fail_connector,
  number_of_products,
  number_of_violations,
  run_method,
  correlation_id
) {
  return {
    date,
    time,
    total_connectors,
    fail_connector,
    number_of_products,
    number_of_violations,
    run_method,
    correlation_id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "id",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Total connectors/Failed",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "No. of products",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "No. of violations",
  },
  {
    id: "car",
    numeric: true,
    disablePadding: false,
    label: "Run method",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ResultSet({ filter }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [resultDetail, setResultDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await httpService.get("/result_set");
        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }

        const data = response.data;
        setRows(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching names:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleDrawer = async (id) => {
    try {
      const response = await httpService.get(`/result_set/${id}`);
      setResultDetail(response?.data?.data);
      setDrawerOpen(!drawerOpen);
    } catch (error) {}
  };

  const renderDrawer = () => (
    <Drawer
      sx={{ width: "40em" }}
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box className="result-set" sx={{ width: "auto", padding: "16px" }}>
        <div className="result-set__date-time-container">
          <div className="result-set__date-time">
            <span className="result-set__date">Date</span>
            <span>{resultDetail?.date}</span>
          </div>
          <div className="result-set__time-container">
            <span className="result-set__time">Time</span>
            <span>{resultDetail?.time}</span>
          </div>
        </div>
        <div className="result-set__detected-container">
          <div className="result-set__detected">
            <span className="result-set__detected-title">
              Total connectors has been detected/Failed connectors
            </span>
            <span>{resultDetail?.total_connectors}</span>
          </div>
        </div>
        <div className="result-set__detected-container">
          <div className="result-set__detected">
            <span className="result-set__detected-title">
              Number of products has been detected
            </span>
            <span>{resultDetail?.no_products}</span>
          </div>
        </div>
        <div className="result-set__detected-container">
          <div className="result-set__detected">
            <span className="result-set__detected-title">
              Number of detected violations
            </span>
            <span className="result-set__detected-violations-length">
              {resultDetail?.no_violations}
            </span>
          </div>
        </div>
        <span className="result-set__connectors-detected-title">
          Connectors has been detected
        </span>
        <div className="result-set__detected-container">
          <div className="result-set__detections-container">
            {resultDetail.detected_connectors?.map((connector) => (
              <span
                key={connector.id}
                className="result-set__detections"
                style={{ borderRadius: "40px", padding: "5px" }}
              >
                {connector.name} / {connector.code}
              </span>
            ))}
          </div>
        </div>
        <span className="result-set__products-detected-title">
          Products has been detected
        </span>
        <div className="result-set__detected-container">
          <div className="result-set__detections-container">
            {resultDetail.detected_products?.map((product) => (
              <span
                key={product.id}
                className="result-set__detections"
                style={{ borderRadius: "40px", padding: "5px" }}
              >
                {product.name}
              </span>
            ))}
          </div>
        </div>
        <span className="result-set__violations-detected-title">
          Detected violations
        </span>
        <div className="result-set__detected-container">
          <div className="result-set__detections-container">
            {resultDetail.detected_violations?.map((violation) => (
              <span
                key={violation.id}
                className="result-set__detections"
                style={{
                  backgroundColor: "rgba(165, 0, 100, 0.10)",
                  color: "#A50064",
                  borderRadius: "40px",
                }}
              >
                {violation.name}
              </span>
            ))}
          </div>
        </div>
        <div className="result-set__buttons">
          <Button
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              width: "45%",
              backgroundColor: "#F6F5F4",
              color: "black",
              textTransform: "none",
            }}
          >
            Close
          </Button>
          <Button
            onClick={() =>
              filter(`results?correlation_id=${resultDetail.correlation_id}`)
            }
            sx={{
              width: "45%",
              backgroundColor: "var(--blue)",
              textTransform: "none",
              color: "white",
              "&:hover": { backgroundColor: "var(--blue)", color: "white" },
            }}
          >
            See the results
          </Button>
        </div>
      </Box>
    </Drawer>
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <section>
        {!loading ? (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer sx={{ width: "auto" }}>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length || 0}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.correlation_id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.correlation_id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell scope="row" sx={{ paddingX: "10px" }}>
                            {row.correlation_id.substring(0, 10)}...
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.date}
                          </TableCell>
                          <TableCell align="center">{row.time}</TableCell>
                          <TableCell align="center">
                            {row.total_connectors}/{row.fail_connector}
                          </TableCell>
                          <TableCell align="center">
                            {row.number_of_products}
                          </TableCell>
                          <TableCell align="center" sx={{ color: "#CA003D" }}>
                            {row.number_of_violations}
                          </TableCell>
                          <TableCell align="center">{row.run_method}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              onClick={() =>
                                filter(
                                  `results?correlation_id=${row.correlation_id}`
                                )
                              }
                            >
                              Results
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "5px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M6 12L10 8L6 4"
                                    stroke="#D9F2FF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="text"
                              sx={{
                                color: "#000",
                                backgroundColor: "#F6F5F4",
                                padding: "6px 12px",
                                borderRadius: "6px",
                              }}
                              onClick={() => toggleDrawer(row.correlation_id)}
                            >
                              Detail
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "5px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M6 12L10 8L6 4"
                                    stroke="#30261D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        sx={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {renderDrawer()}
            </Paper>
          </Box>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default ResultSet;
