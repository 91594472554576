import React from 'react';

function GridIcon() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 3H3V8H10V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 3H14V12H21V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 16H14V21H21V16Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10 12H3V21H10V12Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}

export default GridIcon;
