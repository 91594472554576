import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

function Dount({ data, chartData, labels }) {
  const [medal, setMedal] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const titless = [];
    data.map((info) => {
      return titless.push(info.key);
    });
    setTitles(titless);
    setLoading(false);
  }, []);
  const chartOptions = {
    labels,
    plotOptions: {
      pie: {
        donut: {
          // size: "70%", // Adjust the donut size as needed
        },
      },
    },
    legend: {
      position: "bottom",
    },
  };
  const chartStyle = {
    width: "240px",
  };

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <section
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactApexChart
            style={chartStyle}
            options={chartOptions}
            series={chartData}
            type="donut"
          />
        </section>
      )}
    </>
  );
}
export default Dount;
