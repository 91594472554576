import React, { useEffect, useState } from "react";
import "./products.scss";
import CustomTable from "../../Components/CustomTable/customTable";
import { CircularProgress } from "@mui/joy";
import { useHistory } from "react-router-dom";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

function Product() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tooltipContent, setTooltipContent] = useState({});
  const history = useHistory();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await httpService.get("/product/");

        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }

        const data = response.data.results;

        setProducts(
          data.map((product) => {
            const assignedViolation = product.categories
              .map((category) => category.name)
              .join(" ");

            return {
              id: product.id,
              tableNames: product.name,
              totalAssignees: product.connectors_count,
              is_active: product.is_active,
              assignedViolation: product.categories.map((category) => (
                <div
                  className="custom-table__general-name-item"
                  key={category.id}
                >
                  {category.name}
                </div>
              )),
            };
          })
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const headCells = [
    {
      id: "Product",
      numeric: false,
      disablePadding: true,
      label: "Product",
    },
    {
      id: "AssignedCategories",
      numeric: true,
      disablePadding: false,
      label: "Assigned categories",
    },
    {
      id: "ConnectorsNum",
      numeric: true,
      disablePadding: false,
      label: "No. of connectors",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleBulkDeleteProducts = (selectedIds) => {
    httpService
      .post("/product/bulk_delete/", { ids: selectedIds })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bulk delete successful");
        } else {
          toast.error("Error deleting selected products");
        }
      })
      .catch((error) => {
        toast.error(`Error deleting selected products: ${error}`);
      });
  };

  if (loading) {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <CustomTable
        addButtonText="Add new product"
        addPath="products/add"
        editPath="products/edit"
        headCells={headCells}
        data={products}
        tooltipContent={tooltipContent}
        onBulkDelete={handleBulkDeleteProducts}
        showStatus={false}
        showSearchInput={true}
        showCheckBox={true}
      />
    </>
  );
}

export default Product;
