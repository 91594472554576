import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/joy";
import "./loginPage.scss";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import logo from "../../Icons/img.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { FormControl } from "@mui/base";
import MailIcon from "../../Icons/mailIcon";
import LockIcon from "../../Icons/lockIcon";
import RightArrowIcon from "../../Icons/rightArrowIcon";
import toast, { Toaster } from "react-hot-toast";
import httpService from "../../Services/httpService";

const LoginForm = styled("form")({
  width: "100%",
  marginTop: "1rem",
});

function LoginPage({ onLogin }) {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const ApiURL = `${process.env.REACT_APP_API_BASE_URL}/api/token/`;
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username.trim() === "" || password.trim() === "") {
      return toast.error("Please Provide All values.");
    }
    const data = { username, password };
    try {
      setLoading(true);
      const response = await httpService.post(ApiURL, data);
      if (response.status === 200) {
        const responseData = response.data;
        toast.success("Successfully login!");
        localStorage.setItem("refreshToken", responseData.refresh);
        localStorage.setItem("accessToken", responseData.access);
        localStorage.setItem("superUser", responseData.is_superuser);
        setTimeout(() => {
          onLogin();
          history.replace("/");
        }, 2000);
      } else {
        toast.error("Invalid credentials");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Invalid credentials");
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn) {
      history.push("/");
    }
  }, []);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Toaster />
      <Container
        className="login-page"
        disableGutters
        component="main"
        maxWidth="50%"
        sx={{ padding: "0", height: "100vh", display: "flex" }}
      >
        <div className="login-page__left-side-container">
          <div>
            <img className="login-page__logo" src={logo} alt="" />
          </div>
          <h2 className="login-page__title">Complaint management</h2>
          <span className="login-page__subtitle">panel</span>
        </div>
        <Paper
          elevation={3}
          sx={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "60%",
            justifyContent: "center",
            boxShadow: "none",
          }}
        >
          <div className="login-page__form-contianer">
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                marginTop: "1rem",
                color: "var(--blue)",
                fontWeight: "bold",
              }}
            >
              Login
            </Typography>
            <LoginForm onSubmit={handleLogin}>
              <TextField
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                sx={{ width: "100%", marginBottom: "2em" }}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <OutlinedInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  sx={{ width: "100%", marginBottom: "1em" }}
                  id="outlined-adornment-password"
                  startAdornment={<LockIcon />}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                endIcon={!loading ? <RightArrowIcon /> : null}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "1rem",
                  backgroundColor: "var(--blue)",
                  textTransform: "none",
                }}
              >
                {!loading ? (
                  "Login"
                ) : (
                  <CircularProgress size="sm" sx={{ margin: "auto" }} />
                )}
              </Button>
            </LoginForm>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default LoginPage;
