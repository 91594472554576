import React from "react";
import "./dashboard.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function SelectDate({ width, label, data, country, changeCountry }) {
  return (
    <div>
      {" "}
      <FormControl sx={{ width }}>
        <label htmlFor="demo-multiple-name" className="date-label">
          {label}
        </label>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          placeholder="select"
          value={country}
          onChange={(e) => changeCountry(e.target.value)}
          input={
            <OutlinedInput
              sx={{ backgroundColor: "white", height: "40px" }}
              placeholder="country"
            />
          }
          MenuProps={MenuProps}
        >
          {data?.map((country) => (
            <MenuItem key={country.id} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default SelectDate;
