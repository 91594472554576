import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row({ result, index, handleDetailClick }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ cursor: "pointer" }}
        tabIndex={-1}
        key={result.country + index}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{ paddingLeft: "1em" }}
          component="th"
          scope="result"
          padding="none"
          align="left"
        >
          {result.country}
        </TableCell>
        <TableCell align="left">emails ({result.emails.length})</TableCell>
        <TableCell align="left">{result.created_at}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer sx={{ width: "auto" }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
                      <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Time</TableCell>
                        <TableCell align="left">Connector</TableCell>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="left">Violations</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result.emails?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow tabIndex={-1} key={row.id}>
                            <TableCell
                              sx={{ paddingLeft: "1em" }}
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              {row.date}
                            </TableCell>
                            <TableCell align="left">{row.time}</TableCell>
                            <TableCell align="left">
                              <span className="connector-name">
                                {row.connector?.concated.split("/")[0]}{" "}
                                <span className="connector-name__split">/</span>
                                {row.connector?.concated.split("/")[1]}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              {row.product.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.violations?.map((vio, index) => (
                                <span
                                  key={index}
                                  style={{
                                    backgroundColor: "var(--low-orange)",
                                    color: "var(--dark-orange)",
                                    padding: ".5em",
                                    borderRadius: ".8em",
                                  }}
                                >
                                  {vio}
                                </span>
                              ))}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                onClick={() => handleDetailClick(row)}
                                sx={{
                                  textTransform: "none",
                                  color: "#000",
                                  backgroundColor: "#F6F5F4",
                                  borderRadius: "5px",
                                }}
                              >
                                Detail
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: "4px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 12L10 8L6 4"
                                      stroke="#30261D"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                        <TableRow
                          sx={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
                {/* {renderDrawer()}
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MainEmailTable({ data, handleDetailClick }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ width: "auto" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead sx={{ backgroundColor: "var(--lightest-gray)" }}>
              <TableRow>
                <TableCell />
                <TableCell align="left">Country</TableCell>
                <TableCell align="left">Emails</TableCell>
                <TableCell align="left">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    result={row}
                    index={index}
                    handleDetailClick={handleDetailClick}
                  />
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow
                  sx={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
        {/* <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
