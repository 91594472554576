import React, { useEffect, useState } from "react";
import { Chip, InputLabel, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress } from "@mui/joy";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/base";
import DeleteIcon from "../../Icons/deleteIcon";
import CopyIcon from "../../Icons/copyIcon";
import Textarea from "@mui/joy/Textarea";
import httpService from "../../Services/httpService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "../../Icons/checkIcon";
import Stack from "@mui/material/Stack";
import toast, { Toaster } from "react-hot-toast";

function EditCategory() {
  const history = useHistory();
  const { id } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [violations, setViolations] = useState([{ id: 0, name: "" }]);
  const [related, setRelated] = useState({ count: 0, products: [] });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    isActive(event.target.checked);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDelete = () => {
    const list = [];
    list.push(parseInt(id, 10));
    setLoading(true);

    httpService
      .post("/category/bulk_delete/", { ids: list })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bulk delete successful");
          history.push("/category");
        } else {
          toast.error("Error deleting selected category");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Error deleting selected category: ${error}`);
        setLoading(false);
      });
  };

  const handleDuplicate = () => {
    const viols = data.violations.map((item) => item.id);
    const formData = {
      name: data.name,
      description: data.description,
      is_active: data.is_active,
      violations: viols,
    };
    setLoading(true);
    httpService
      .post("/category/", formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Category duplicated successfully!");
        } else {
          toast.error("Category duplicated failed!");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error}`);

        setLoading(false);
      });
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedViolations(value);
  };

  const handleSave = () => {
    const viols = [];
    for (let cat of violations) {
      for (let option of selectedViolations) {
        if (cat.name === option) {
          viols.push(cat.id);
        }
      }
    }
    const formData = {
      name: categoryName,
      description: description,
      is_active: isActive,
      violations: viols,
    };
    setLoading(true);
    httpService
      .put(`/category/${id}/`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Category changes successfully!");
        } else {
          toast.error("Category changes failed!");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Error sending data to API: ${error}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const violationsResponse = await httpService.get("/violation/");
        const violationsData = violationsResponse.data.results;
        setViolations(
          violationsData.map((item) => ({
            id: item.id,
            name: item.violation_general_name.name,
          }))
        );
        const categoryResponse = await httpService.get(`/category/${id}/`);
        const categoryData = categoryResponse.data;
        setSelectedViolations(
          categoryData.violations.map(
            (item) => item.violation_general_name.name
          )
        );
        setCategoryName(categoryData.name);
        setIsActive(categoryData.is_active);
        setDescription(categoryData.description);
        setRelated(categoryData.related_products);
        setData(categoryData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <div className="edit-category-container">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <Button
              // startIcon={<ArrowBackIcon sx={{width: '1.5em', height: '1.5em', color: 'var(--light-brown)'}}/>}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                color: "var(--blue)",
                fontWeight: "bold",
                fontSize: "1.5em",
                display: "flex",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => history.goBack()}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                >
                  <path
                    d="M25.3337 16.5H6.66699"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0003 25.8334L6.66699 16.5001L16.0003 7.16675"
                    stroke="#64513D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Edit {categoryName ? categoryName : null}
            </Button>
            <div className="edit-category-container__form-container">
              <InputLabel
                htmlFor="component-simple"
                sx={{ marginBottom: "1em" }}
              >
                Main info
              </InputLabel>
              <TextField
                className="edit-category-container__category-name"
                label="Edit Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                id="outlined-basic"
                placeholder="Category name"
                variant="outlined"
                sx={{ width: "20vw", backgroundColor: "white" }}
              />
              <Textarea
                sx={{ marginTop: "2em" }}
                minRows={4}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder="Update Description (Optional)"
              />
              <FormControlLabel
                sx={{ margin: "2em 0" }}
                labelPlacement="start"
                control={
                  <Switch
                    checked={isActive}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Activation"
              />
              <span className="edit-category-container__assignee-title">
                Assign violations(S)
              </span>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{ fontSize: ".7em", marginLeft: ".5em" }}
              >
                Violation(S)
              </InputLabel>
              <FormControl sx={{ width: 300 }}>
                <Select
                  sx={{ width: "100%", backgroundColor: "white" }}
                  multiple
                  value={selectedViolations}
                  onChange={handleSelectChange}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected.map((violation, index) => (
                        <Chip
                          key={index}
                          label={violation}
                          onDelete={() =>
                            setSelectedViolations((prevSelected) =>
                              prevSelected.filter(
                                (option) => option !== violation
                              )
                            )
                          }
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          color="primary"
                          variant="filled"
                        />
                      ))}
                    </Stack>
                  )}
                >
                  {violations.map((violation) => (
                    <MenuItem
                      key={violation.id}
                      value={violation.name}
                      sx={{ justifyContent: "space-between" }}
                    >
                      {violation.name}
                      {selectedViolations.includes(violation.name) ? (
                        <CheckIcon color="info" />
                      ) : null}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                <p className="edit-category-container__actions-title">
                  Actions
                </p>
                <Button
                  startIcon={<CopyIcon />}
                  onClick={handleDuplicate}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: "0.4em 2em",
                    marginRight: "1em",
                    stroke: "white",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Duplicate
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "var(--pink)",
                    color: "white",
                    padding: "0.4em 2em",
                    "&:hover": {
                      backgroundColor: "var(--pink)",
                      color: "var(--white)",
                    },
                  }}
                >
                  Delete
                </Button>
              </div>
              <div className="edit-category-container__assigned-products">
                <span className="edit-category-container__assigned-products-title">
                  Assigned to {related.count} products
                </span>
                <div className="edit-category-container__assignees-container">
                  {related.products?.map((product, index) => (
                    <span
                      key={index}
                      className="edit-category-container__assignees-items"
                    >
                      {product}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="edit-category-container__buttons-container">
              <div className="edit-category-container__buttons">
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    backgroundColor: "var(--lightest-gray)",
                    color: "var(--darkest-gray)",
                    padding: ".5em 2.5em",
                    "&:hover": {
                      backgroundColor: "var(--lightest-gray)",
                      color: "var(--darkest-gray)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  sx={{
                    backgroundColor: "var(--blue)",
                    color: "white",
                    padding: ".5em 3em",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                      color: "white",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default EditCategory;
