import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/joy";
import "./categories.scss";
import CustomTable from "../../Components/CustomTable/customTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import httpService from "../../Services/httpService";
import toast, { Toaster } from "react-hot-toast";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tooltipContent, setTooltipContent] = useState({});
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await httpService.get("/category/");

        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          return history.push("/login");
        }

        const data = response.data.results;

        setCategories(
          data.map((category) => ({
            id: category.id,
            tableNames: category.name,
            totalAssignees: category.products_count,
            assignedViolation: category.violations.map((violation) => {
              let style;
              if (violation.violation_general_name.name.includes("discount")) {
                style = "custom-table__general-name-discount";
              } else if (
                violation.violation_general_name.name.includes("word")
              ) {
                style = "custom-table__general-name-word";
              } else if (
                violation.violation_general_name.name.includes("promotion")
              ) {
                style = "custom-table__general-name-promotion";
              } else {
                style = "custom-table__general-name-item";
              }

              return (
                <div className={style} key={violation.id}>
                  {violation.violation_general_name.name}
                </div>
              );
            }),
            is_active: category.is_active,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [history]);

  const headCells = [
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
    },
    {
      id: "AssignedViolations",
      numeric: true,
      disablePadding: false,
      label: "Assigned violations",
    },
    {
      id: "categoryNum",
      numeric: true,
      disablePadding: false,
      label: "No. of products",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleBulkDeleteCategories = async (selectedIds) => {
    try {
      const response = await httpService.post("/category/bulk_delete/", {
        ids: selectedIds,
      });
      if (response.status === 200) {
        toast.success("Bulk delete successful");
        const newCats = categories.filter(
          (cat) => !selectedIds.includes(cat.id)
        );
        setCategories([...newCats]);
      } else {
        toast.error("Error deleting selected categories");
      }
    } catch (error) {
      toast.error(`Error deleting selected categories: ${error}`);
    }
  };

  const LoadingComponent = () => {
    return (
      <div className="circular-progress-loader">
        {" "}
        <CircularProgress sx={{ margin: "auto" }} />{" "}
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <section>
        {!loading ? (
          <CustomTable
            addButtonText="Add new category"
            addPath="/categories/add"
            editPath="/categories/edit"
            headCells={headCells}
            data={categories}
            tooltipContent={tooltipContent}
            onBulkDelete={handleBulkDeleteCategories}
            showGeneralName={false}
            showSearchInput={true}
            showCheckBox={true}
          />
        ) : (
          <LoadingComponent />
        )}
      </section>
    </>
  );
}

export default Categories;
